import { ChatMessage } from './chat.modal';
import { Constants } from './constant';
import { CallStatus } from './call.modal';
import { AgentResponseData, BookingConfig } from './agent.modal';

export enum RoutesUrls {
  video_call = 'video-call',
  call_in_future = 'call-in-future',
  live_call = 'live-call',
  close = 'close',
  feedback = 'feedback',
  message_us = 'message-us',
  queue_intermediate = 'queue-intermediate'
}

export interface OutboundInfo {
  snowplow_domain_userid: string;
  invitecode: string;
  roomName: string;
}

export interface CobrowseInfo {
  roomName: string;
  cobrowseAction: 'initiated' | 'ended' | 'observe';
  clientIdentity: string;
}

export interface OutboundAcceptRes {
  room_name: string;
}

export interface LocalPluginStates {
  roomInfo?: RoomInfo;
  callInfo?: CallInfo;
  language: string;
  bookingSessionInfo?: InviteData;
  guestStatus: string;
  isJoinCall?: boolean;
  deviceInfo?: DeviceInfo;
}

export interface SessionPluginStates {
  callStatus: boolean;
  Agent_Status_Info: AgentResponseData;
  bookingConfig: BookingConfig;
  colorConfig: { name: string, value: string }[];
  externalLinkTrackLabel?: string;
  outboundFirstInitiated?: boolean;
}

export const PluginStates = {
  roomInfo: 'roomInfo',
  callInfo: 'callInfo',
  agentInfo: 'agentInfo',
  agentStatus: 'agentStatus',
  language: 'language',
  bookingSessionInfo: 'bookingSessionInfo',
  deviceInfo: 'deviceInfo',
  callStatus: 'callStatus',
  cobrowsePosition: 'cobrowsePosition',
  guestStatus: 'guestStatus',
  colorConfig: 'colorConfig',
  bookingConfig: 'bookingConfig',
  isJoinCall: 'isJoinCall',
  externalLinkTrackLabel: 'externalLinkTrackLabel',
  outboundFirstInitiated: 'outboundFirstInitiated',
  autoQueueInfo: 'autoQueueInfo'
}

export enum AutoQueueInfoStates {
  activeScreenForHeader = 'activeScreenForHeader',
  currentScreenIndex = 'currentScreenIndex',
  answeredQuestions = 'answeredQuestions',
  currentQuestionIndex = 'currentQuestionIndex',
  waitScreenTemplate = 'waitScreenTemplate',
  waitScreenInitiatedTime = 'waitScreenInitiatedTime',
  contactFormSelectionOpt = 'contactFormSelectionOpt'
}

export enum RoomInfoStates {
  agentName = 'agentName',
  closeDt = 'closeDt',
  guestId = 'guestId',
  guestToken = 'guestToken',
  inviteLink = 'inviteLink',
  isOutboundCall = 'isOutboundCall',
  queueInviteCode = 'queue_invite_code',
  queuePositionId = 'queuePositionId',
  queueId = 'queueId',
  roomName = 'roomName',
  roomStatus = 'roomStatus',
  startDt = 'startDt',
  userInfo = 'userInfo',
  memberSid = 'memberSid',
  unConsumedIndex = 'unConsumedIndex',
  outboundAccepted = 'outboundAccepted',
  timeKillerAnswers = 'timeKillerAnswers',
  lastCallState = 'lastCallState',
  lastChatScrollPosition = 'lastChatScrollPosition',
  lastParticipantInFull = 'lastParticipantInFull',
  participantList = 'participantList',
  lastCallTimeStamp = 'lastCallTimeStamp',
  isVideoCallStarted = 'isVideoCallStarted',
  agentOnTheVideoCall = 'agentOnTheVideoCall',
  routeState = 'routeState'
}

export enum CallInfoStates {
  audio = 'audio',
  video = 'video',
  previewing = 'previewing',
  isWindowMinimized = 'isWindowMinimized',
  chat = 'chat',
  isCobrowseHidden = 'isCobrowseHidden',
  screenStatus = 'screenStatus',
}

export interface RoomInfo {
  inviteLink: string;
  roomName: string;
  queuePositionId: number;
  guestToken: string;
  startDt: string;
  closeDt: number;
  roomStatus: CallStatus;
  routeState: string;
  userInfo: UserInfo;
  guestId: string;
  agentName?: string;
  isOutboundCall?: boolean;
  queue_invite_code?: string;
  outboundAccepted?: boolean;
  timeKillerAnswers?: number;
  sentMessages?: { [key in string]: ChatMessage };
  lastCallState?: string;
  lastParticipantInFull?: string;
  memberSid?: string;
}

export interface CallInfo {
  audio: boolean;
  video: boolean;
  isWindowMinimized: boolean;
  chat: boolean;
  screenStatus: string;
  previewing: string;
  isCobrowseHidden: boolean;
}

export interface DeviceInfo {
  audioInDevice: string;
  audiOutDevice: string;
  videoInDevice: string;
}

export enum DeviceInfoStates {
  audioInDevice = 'audioInDevice',
  audiOutDevice = 'audiOutDevice',
  videoInDevice = 'videoInDevice'
}

export interface PluginConfig {
  languageConfig: LanguageConfig;
}

export interface LanguageConfig {
  type: string;
  key: string;
  value: string;
}

export enum LanguageType {
  url = 'url',
  localstorage = 'localstorage',
  cookies = 'cookies'
}

export interface SnowPlowEvent {
  category: string;
  action: string;
  label: string;
  property?: string;
  value?: number;
}

interface UserInfoKeys {
  [key: string]: any;
}

export interface UserInfo extends UserInfoKeys {
  id?: string;
  full_name?: string;
  ip: string;
  queue_id: number;
  tenantid: number;
  user_agent?: string | any;
  email?: string;
  phone?: string;
}
export interface InviteRes {
  data: InviteData;
  status: number;
}
export interface InviteData {
  account_name: string;
  account_number: string;
  closed: string;
  comment: string;
  corporate: string;
  duration: string;
  email: string;
  expiry: number;
  full_name: string;
  guest_link: string;
  guest_modify_link: string;
  guest_cancel_link: string;
  guest_user_id: string;
  invite_id: number;
  lang: string;
  outbound: number | null;
  outbound_accepted_timestamp: string | null;
  phone: string;
  postal_code: string;
  products_services: string;
  queue_id: number;
  queue_position_id: number;
  room_name: string;
  segment: string;
  sequence: number;
  start_dt: string;
  status: string;
  tenantid: number;
  uuid: string;
}

export interface RoomData {
  room_name: string
  queue_position_id: number
  room_status: string
}

export interface RoomCreatedData {
  room_name: string;
  sendFromSmallRoom: boolean;
  starting: boolean;
}

export interface RoomCloseData {
  roomClosed: boolean;
  channelSid: string;
}

export const QueryParamsForWhiteList: string[] = ['resumeCall', 'resumeWaiting', 'callCompleted'];

export enum UserInfoRequiredField {
  name = 'name',
  placeholder = 'Name (required)',
  email = 'email',
  phone = 'phone',
  queue_id = 'queue_id'
}

export const UserFormFieldValidations = [{
  name: 'email',
  regex: Constants.emailRegex
}, {
  name: 'phone',
  regex: Constants.phoneRegex
}]

export interface CommonResponse {
  message: string;
}

export enum QueryParamsOptimyScreen {
  booking = 'booking',
  cancelBooking = 'cancelbooking',
  editBooking = 'editbooking',
  joinQueue = 'joinqueue',
  leaveMessage = 'leavemessage',
  messageus = 'messageus',
}

export const OptimyScreenValuesOfBooking: string[] = ['booking', 'editbooking', 'cancelbooking'];

export enum GuestStatus {
  inQueue = 'In Queue',
  inCall = 'In Call',
  available = 'Available',
}

export enum InviteLinkStatus {
  expired = 'expired'
}

export const MessageOptions = ['email', 'phone'];

export interface OminiReq {
  channel: string;
  content: string;
}

export interface ResizeState {
  isMaximized: boolean;
  isMinimized: boolean;
  isDefault?: boolean;
}

export interface InCallState {
  inCall: boolean;
}

export interface RoomEndSocket {
  room_name: string;
  auto_reject?: number;
}

export enum updateScheduleStatus {
  missed = 'Missed'
}

export enum InviteCodeParams {
  optimyCode = '?optimy_code=',
  code = '?code=',
  optimyScreen = 'optimy_screen'
}

export interface Context {
  schema: string;
  data: ContextData
}

export interface ContextData {
  [key: string]: any;
}

export interface QueueUpdateReq {
  customer_contact: QueueUpdateContact;
  comment: string;
  message_left: number;
  guest_hostname: string;
  lang: string;
  guest_locale: string;
  domain_sessionid: string;
}

export interface QueueUpdateContact {
  full_name: string;
  phone?: string;
  email?: string;
}

export interface MemberUpdatedSocket {
  updated: boolean;
  channelSid: string;
}

export interface AgentStatusCacheValues {
  [key: string]: {
    num_options: number,
    option_index: number
  }
}

export enum NudgeContent {
  htmlContent='htmlContent',
  isNudgeClick = 'isNudgeClick'
}

export enum QueueTemplates {
  leaveMessage = 'leaveMessage',
  start = 'start',
  contact = 'contact',
  questions = 'questions',
  waiting = 'waiting'
}

export enum ActiveScreen {
  leaveMessage = 'leaveMessage',
  waitingScreen = 'waitingScreen'
}

export enum CallDropOptions  {
  noThanks ='thanks',
  yesTextMe = 'yesTextMe'
}

export interface EventKeys {
  label: string;
  property: string;
}
export enum WaitTemplate {
  waitIntro = 'waitIntro',
  waitNoShowWithMobile = 'waitNoShowWithMobile',
  waitNoShowWithoutMobile = 'waitNoShowWithoutMobile',
  waitAgentJoin = 'waitAgentJoin'
}

