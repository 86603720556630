<div class="queue-wait">
    <ng-container [ngSwitch]="currentTemplate">
        <ng-template [ngSwitchCase]="waitTemplate.waitIntro">
            <div class="queue-wait-intro op-d-flex op-align-items-center op-flex-column">
                <div class="op-d-flex op-align-items-center op-flex-column message">
                    <svg-icon name="magnet-dude"></svg-icon>
                    <h5 class="op-mt-2" [innerHTML]="'intermediateScreen.hangOnSec' | translate"></h5>
                    <p class="op-mt-3" [innerHTML]="'intermediateScreen.pickingBestTeamMember' | translate"></p>
                </div>
                <div class="op-d-flex op-align-items-center op-justify-content-center timeKiller-message">
                    <span>{{timeKillerMessage}}</span>
                </div>
            </div>
        </ng-template>
        <ng-template [ngSwitchCase]="waitTemplate.waitNoShowWithMobile">
            <div class="op-d-flex op-align-items-center op-flex-column no-show">
                <div class="op-d-flex op-align-items-center op-flex-column message">
                    <svg-icon name="mobile-conversation"></svg-icon>
                    <h5 class="op-mt-2" [innerHTML]="'intermediateScreen.howAwkward' | translate"></h5>
                    <p class="op-mt-3" [innerHTML]="'intermediateScreen.longerThanUsualTime' | translate"></p>
                </div>
            </div>
            <div class="op-d-flex op-align-items-center op-justify-content-center">
                <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1" appAddSnowplowEvent
                        [category]="spTracker.labels.inboundQueue" [action]="spTracker.labels.close"
                        [label]="spTracker.labels.mainQueueTimeoutText" [property]="spTracker.properties.button"
                        (click)="closeWait()" [innerHTML]="'common.close' | translate"></button>
            </div>
        </ng-template>
        <ng-template [ngSwitchCase]="waitTemplate.waitNoShowWithoutMobile">
            <div class=" op-d-flex op-align-items-center op-flex-column no-show">
                <div class="op-d-flex op-align-items-center op-flex-column message">
                    <svg-icon name="lap-image"></svg-icon>
                    <h5 class="op-mt-2" [innerHTML]="'intermediateScreen.noMobileHeading' | translate"></h5>
                    <p class="op-mt-3" [innerHTML]="'intermediateScreen.noMobilePara' | translate"></p>
                </div>
            </div>
            <div class="op-d-flex op-align-items-center op-justify-content-center">
                <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1" appAddSnowplowEvent
                        [category]="spTracker.labels.inboundQueue" [action]="spTracker.actions.leaveMessage"
                        [label]="spTracker.labels.mainQueueTimeoutNoText"
                        (click)="emitWaitScreenAction.emit(false)"
                        [innerHTML]="'intermediateScreen.yesLikeToSwitch' | translate"></button>
            </div>
        </ng-template>
        <ng-template [ngSwitchCase]="waitTemplate.waitAgentJoin">
            <div class=" op-d-flex op-align-items-center op-flex-column queue-wait-agent-joined">
                <div class="op-d-flex op-align-items-center op-flex-column op-mt-3" *ngIf="agentJoined">
                    <h4>
                        <span [innerHTML]="'intermediateScreen.agentName' | translate"></span><span class="blue">{{agentName}}</span>
                    </h4>
                    <div class="profile-image op-mt-3">
                        <img alt="no avatar" *ngIf="agentJoined?.agent_avatar" [src]="agentJoined?.agent_avatar">
                        <span *ngIf="!agentJoined?.agent_avatar">{{agentName?.split('')[0]}}</span>
                    </div>
                    <div class="rating op-mt-2" *ngIf="ratingObj">
                        <div class="op-d-flex op-align-items-center op-justify-content-center">
                            <div class="stars" [ngStyle]="{'--rating' : ratingObj?.rating_avg}"></div>
                            <b class="op-mx-1">{{ratingObj?.rating_avg}}</b><span>({{ratingObj?.rating_count}})</span>
                        </div>
                    </div>
                    <div class="lang op-d-flex op-align-items-center op-flex-column op-mt-3">
                        <span [innerHTML]="'intermediateScreen.agentCanServe' | translate"></span>
                        <div class="lang-list">
                            <span *ngFor="let lang of languages">{{lang}}</span>
                        </div>
                    </div>
                </div>
                <div class="count-down op-d-flex op-align-items-center op-flex-column">
                    <span [innerHTML]="'intermediateScreen.callStartIn' | translate"></span>
                    <div class="count-circle op-mt-2 op-d-flex op-align-items-center op-justify-content-center">
                        {{countDown}}
                    </div>
                </div>
            </div>

        </ng-template>
    </ng-container>
</div>
