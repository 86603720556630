import { Injectable } from '@angular/core';
import * as LogRocket from 'logrocket';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { environment } from 'src/environments/environment';
import { AgentResponseData, AgentStatus } from '../../constants/agent.modal';
import { SnowplowService } from './../snowplow/snowplow.service';

interface LogRocketIdentity extends IUserTraits {
  licenseKey: string,
  domain_sessionid: string,
  domain_userid: string,
  tenantid: number
};

interface IUserTraits {
  [propName: string]: string | number | boolean,
}

// const LOG_ROCKET_IDENTITY = 'logCurrentSession';
@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  initializeLogging$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private snowplowService: SnowplowService,
    private utils: UtilsService) {

    this.initializeLogging$.subscribe(() => {
      this._initializeLogRocket();
    });
  }

  private _initializeLogRocket(): void {
    const identity = this._getCurrentSessionIdentity();
    LogRocket.init(environment.logRocketProjectIdentification);
    LogRocket.identify(identity);
    // if (!this._hasInitializedLogRocket()) {
    // console.log('initializing LogRocket', identity);
    // this._setLogRocketIdentity();
    // }
  }

  private _getCurrentSessionIdentity(): LogRocketIdentity {
    const currentSnowplowIdSession = this.snowplowService.getSnowplowUserId();
    const guestSnowplowUserSessionId = this.snowplowService.getSnowplowSessionId();
    const agentDetails: AgentResponseData = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    return {
      licenseKey: (window as any).OPTIMY_LICENSE_KEY,
      domain_sessionid: guestSnowplowUserSessionId,
      domain_userid: currentSnowplowIdSession,
      tenantid: agentDetails.tenant_id
    };
  }

  public addIdentityToLogRocket(guestId: string): void {
    const identity = this._getCurrentSessionIdentity();
    LogRocket.identify(guestId, identity);

  }
  // This code is to validate if we should or not initialize LogRocket, but not important for now
  // private _hasInitializedLogRocket(): boolean {
  //   const currentSessionInfo = this.utils.getLocalVal(LOG_ROCKET_IDENTITY) as LogRocketIdentity;
  //   const identity = this._getCurrentSessionIdentity();
  //   return currentSessionInfo?.domain_sessionid === identity.domain_sessionid
  //     && currentSessionInfo?.domain_userid === identity.domain_userid;
  // }

  // private _setLogRocketIdentity(): void {
  //   this.utils.setLocalVal(null, [LOG_ROCKET_IDENTITY], [this._getCurrentSessionIdentity()]);
  // }

}
