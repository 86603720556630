import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LeaveMessageComponent } from './leave-message/leave-message.component';
import { UtilsService } from '../../core/services/utils/utils.service';
import { SharedService } from '../../core/services/shared/shared.service';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { ChatService } from '../../core/services/chat/chat.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from '../../core/services/socket/socket.service';
import {
  AutoQueueInfoStates, GuestStatus,
  PluginStates,
  QueueTemplates, RoomInfo,
  RoomInfoStates, RoutesUrls, updateScheduleStatus, UserInfo
} from '../../core/constants/common.enum';
import { AgentResponseData, AgentStatus } from '../../core/constants/agent.modal';
import { CallStatus, CreateReq, RouteSate } from '../../core/constants/call.modal';
import { Participant } from '../video-call/video-call.types';
import { MessageCreateReq } from '../../core/constants/chat.modal';
import { Constants } from '../../core/constants/constant';
import { ScreenOrientationService } from '../../core/services/utils/screen-orientation.service';

@Component({
  selector: 'app-queue-intermediate-screen',
  templateUrl: './queue-intermediate-screen.component.html',
  styleUrls: ['./queue-intermediate-screen.component.scss']
})
export class QueueIntermediateScreenComponent implements OnInit, OnDestroy {
  @ViewChild('leaveMessageCmp') leaveMessageCmp: LeaveMessageComponent;

  agentStatusInfo: AgentResponseData;
  currentTemplateIndex: number;
  currentTemplate: string;
  guestToken: string;
  guestId: string;
  isMobile = false;
  participants: Participant[]
  queueTemplates = QueueTemplates;
  queueTemplateList: string[];
  roomName: string;
  userInfo: UserInfo;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private chatService: ChatService,
              private translate: TranslateService,
              private router: Router,
              private screenOrientationService: ScreenOrientationService,
              private sharedService: SharedService,
              private snowplowService: SnowplowService,
              private socketService: SocketService,
              private spinner: NgxSpinnerService,
              private utils: UtilsService,
              private videoCallService: VideoCallService) {
    this.sharedService.updatedAgentInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe(
            {
              next: () => {
                this.agentStatusInfo = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
              },
            });
    this.socketService.memberUpdatedSocket
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            if (this.roomName === data.channelSid) {
              this.updateParticipantList();
            }
          }
        });
    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isMobile = this.screenOrientationService.isOnMobile();
        });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.isMobile = this.screenOrientationService.isOnMobile();
    const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.guestId = roomInfo?.guestId;
    this.guestToken = roomInfo?.guestToken;
    this.roomName = roomInfo?.roomName;
    this.userInfo = roomInfo?.userInfo;
    this.agentStatusInfo = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.queueTemplateList = this.agentStatusInfo?.join_queue_config?.screens;
    this.currentTemplateIndex = this.agentStatusInfo?.are_agents_online ? -1 : this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.currentScreenIndex) || 0;
    this.checkAndSetFirstScreen();
  }

  checkAndSetFirstScreen() {
    if (this.agentStatusInfo?.are_agents_online) {
      if (this.queueTemplateList?.length) {
        this.currentTemplateIndex = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.currentScreenIndex) || 0;
        this.currentTemplate = this.currentTemplateIndex === -1 ?  this.queueTemplates.leaveMessage : this.queueTemplateList[this.currentTemplateIndex]
        if (this.currentTemplate ===  this.queueTemplates.waiting && !this.guestToken) {
          this.createChannelAndGuest();
        }
      } else {
        this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], {skipLocationChange : true});
      }
    } else {
      const indexInStore = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.currentScreenIndex);
      if(this.queueTemplateList.includes(this.queueTemplates.start) && indexInStore !== -1) {
        this.currentTemplateIndex = 0;
        this.currentTemplate =  this.queueTemplates.start;
      } else {
        this.currentTemplateIndex = -1;
        this.currentTemplate =  this.queueTemplates.leaveMessage;
      }
    }
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.currentScreenIndex], [this.currentTemplateIndex]);
  }

  onActionClick() {
    if(this.agentStatusInfo?.are_agents_online) {
      this.currentTemplateIndex = ++this.currentTemplateIndex;
      const nextScreen = this.queueTemplateList[this.currentTemplateIndex];
      if (nextScreen === this.queueTemplates.waiting) {
        this.currentTemplate = this.queueTemplateList[this.currentTemplateIndex];
        this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.currentScreenIndex], [this.currentTemplateIndex]);
        this.createChannelAndGuest();
      } else if (this.currentTemplateIndex >= this.queueTemplateList?.length) {
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
        this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], {skipLocationChange: true});
      } else {
        this.currentTemplate = this.queueTemplateList[this.currentTemplateIndex];
        this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.currentScreenIndex], [this.currentTemplateIndex]);
      }
    } else {
     this.navigateToLeaveMessage();
    }
  }

  navigateToLeaveMessage() {
    this.currentTemplate = this.queueTemplates.leaveMessage;
    this.currentTemplateIndex = -1;
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.currentScreenIndex], [this.currentTemplateIndex]);
  }

  createChannelAndGuest() {
    this.spinner.show();
    this.userInfo = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.userInfo);
    let req: CreateReq = this.utils.createGuestReqValues();
    const createGuest = this.videoCallService.createGuest(req)
    const createChannel = this.videoCallService.createChannel();
    forkJoin([createGuest, createChannel]).subscribe((res) => {
      this.guestToken = res[0]?.guesttoken;
      this.guestId = res[0]?.id;
      this.roomName = res[1]?.sid;
      this.socketService.joinRoom(this.roomName);
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.guestToken, RoomInfoStates.guestId, RoomInfoStates.roomName],
          [this.guestToken, this.guestId, this.roomName])
      this.addMemberAndJoinQueue();
    }, () => {
      this.spinner.hide();
      console.log('error in joining queue')
    })
  }

  addMemberAndJoinQueue() {
    let attr: any = {
      room_name: this.roomName,
      guest_hostname: this.utils.removeExtraParamsFormUrl(),
      lang: this.utils.getLocalVal(PluginStates.language),
      guest_locale: navigator.language,
      domain_sessionid: this.utils.getDomainSessionId(),
      num_customers_in_queue: this.agentStatusInfo?.num_customers_in_queue,
      num_agents_available_now: this.agentStatusInfo?.agents_available_now,
      domain_userid: this.snowplowService.getSnowplowUserId(),
    };
    this.utils.setLocalVal(
        PluginStates.roomInfo,
        [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
        [CallStatus.pending, RouteSate.waiting]
    );
    const attributes = {
      ...this.userInfo,
      cameraOff: true,
      micOff: true,
      isOffline: false,
      domain_userid : this.snowplowService.getSnowplowUserId()
    };
    const addUserToQueue = this.videoCallService.addUserToQueue(this.guestToken, this.userInfo?.queue_id, { attr: JSON.stringify(attr), status: CallStatus.pending});
    const addMember = this.chatService.createMember(this.guestId, this.roomName, attributes);
    forkJoin([addUserToQueue, addMember]).subscribe((res) => {
      this.spinner.hide();
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.memberSid, RoomInfoStates.queuePositionId, RoomInfoStates.inviteLink],
          [res[1]?.sid, res[0]?.id, res[0]?.guest_link]);
      this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, this.guestToken);
      this.updateMemberAndSendFirstMessage();
      this.pingGuest();
    }, () => {
      this.spinner.hide();
      console.log('error in joining queue')
    })
  }

  updateMemberAndSendFirstMessage() {
    const req: MessageCreateReq = {
      channelSid: this.roomName,
      message: this.translate.instant('inCall.chat.joinMessage', {userName: this.guestId?.split('_')[1]}),
      identity: 'system',
    };
    const sendMemberUpdateSocket = this.chatService.memberUpdated(this.roomName);
    const createGuestJoinMessage = this.chatService.sendChatMessages(req);
    forkJoin([sendMemberUpdateSocket, createGuestJoinMessage]).subscribe(
        () => {
          console.log('queue joined successfully')
        });
  }

  pingGuest() {
    this.pingFn();
    interval(10000).pipe(takeUntil(this.destroy$)).subscribe(() => {
      const token = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
      if (token) {
        this.pingFn();
      }
    });
  }

  pingFn() {
    this.utils.pingUser(this.guestToken).pipe(takeUntil(this.destroy$)).subscribe(() => {
      console.log('ping success');
    }, (e) => {
      if (e.status === 401 || e.status === 403) {
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo], [RoomInfoStates.guestToken]);
      }
    });
  }


    removeFromQueue(justClose: boolean) {
    const roomInfo: RoomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    const bodyParams: any = { status: updateScheduleStatus.missed };
    this.videoCallService.queuePositionUpdate(roomInfo?.queuePositionId, roomInfo?.guestToken, bodyParams).subscribe(()=> {
      this.utils.checkAndSetGuestStatus(GuestStatus.available, roomInfo?.guestToken);
      this.sendLeftMessageAndRemoveMember(roomInfo?.roomName, roomInfo.memberSid)
      if (justClose) {
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
        this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], {skipLocationChange: true});
      } else {
        this.navigateToLeaveMessage()
      }
    });
  }

  sendLeftMessageAndRemoveMember(roomName: string, memberSid: any) {
    const currentParticipant = this.participants?.find(
        (res) => res?.identity === this.guestId
    );
    const attributes = {
      ...currentParticipant?.attributes,
      disconnected_timestamp: moment().utc(),
      roomName: this.roomName
    };
    const req: MessageCreateReq = {
      channelSid: roomName,
      message: this.translate.instant('inCall.chat.leftMessage', {
        userName: this.guestId?.split('_')[1]
      }),
      identity: 'system'
    };

    const updateMember = this.chatService.updateMember(attributes, memberSid);
    const createGuestLeftMessage = this.chatService.sendChatMessages(req);
    forkJoin([updateMember, createGuestLeftMessage]).subscribe(() => {
      console.log('chat room updated')
    })
  }

  updateParticipantList() {
    this.videoCallService.getParticipantList(this.roomName).subscribe(res=> {
      this.participants = res;
      const firstAgent = this.participants?.find((participant)=> !participant?.identity?.includes('guest'));
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.agentName, RoomInfoStates.participantList], [firstAgent?.attributes?.first_name, this.participants]);
    });
  }

}
