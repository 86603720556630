import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AllInputFields, QueryParamList } from 'src/app/core/constants/booking-appointment.modal';
import { environment } from '../../../../environments/environment';
import { AgentResponseData, AgentStatus } from '../../constants/agent.modal';
import {
  BubbleChoice,
  CallStatus,
  CreateReq,
  GuestCreateDefaultFields,
  TimeExceedAction
} from '../../constants/call.modal';
import {
  AgentStatusCacheValues, AutoQueueInfoStates, CallDropOptions,
  CallInfo,
  CallInfoStates, Context, EventKeys, GuestStatus, InviteCodeParams,
  PluginStates, QueueTemplates,
  RoomInfoStates, RoutesUrls, UserInfo, UserInfoRequiredField, WaitTemplate
} from '../../constants/common.enum';
import { Constants } from '../../constants/constant';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels,
  SnowplowTrackerProperties, SnowplowTrackerSchemas
} from '../../constants/trackerLabels';
import { HttpWrapperService } from '../http-service/http-wrapper.service';
import { SharedService } from '../shared/shared.service';
import { SnowplowService } from '../snowplow/snowplow.service';
import { VideoCallService } from '../video-call/video-call.service';
import { AppStore } from '../../../store/app.states';
import * as appActions from '../../../store/app.actions';
import { SocketService } from '../socket/socket.service';
import { Md5 } from 'ts-md5';
import { CustomButton, RedirectAction } from '../../constants/chat.modal';

export const notCustomField = [
  'name',
  'full_name',
  'email',
  'phone',
  'comment',
  'duration',
  'guest_hostname',
  'guest_locale',
  'guest_tz',
  'lang',
  'start_dt',
  'room_name',
  'queue_id'];

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  sourceURL: string;
  ip: string;
  foundSnowPlowId$: Subject<boolean> = new Subject<boolean>();
  originalTittle: string;
  soundMp3 = new Audio('https://save-optimy-cdn-staging.s3.ca-central-1.amazonaws.com/assets/media/notification-pristine.mp3');
  messageCount = 0;
  currentTitleState = false;
  audioPlayed = false;
  titleChangeInterval: any;
  titleChangeEnded = true;
  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties,
    schemas: SnowplowTrackerSchemas
  };
  licenseKey: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
      private router: Router,
      private videoCallService: VideoCallService,
      private snowplowService: SnowplowService,
      private httpClientWrapper: HttpWrapperService,
      private socketService: SocketService,
      private sharedService: SharedService,
      private store: Store<AppStore>,
      private translate: TranslateService,
      @Inject(Constants.licenseKey) licenseKey: string) {
    this.licenseKey = licenseKey;
    this.originalTittle = document.title;
    this.socketService.reJoinRooms
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.reJoinAllTheRooms();
        });
  }


  public getIPAddress(): Observable<any> {
    return this.httpClientWrapper.getExternal(Constants.ipfyUrl)
        .pipe(map(data => {
          this.ip = data.ip;
          return data.ip;
        }))
        .pipe(catchError(e => throwError(e)));
  }

  public getMinutesLeft(estimatedTime: Date): number {
    let diff = (estimatedTime.getTime() - new Date().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  public pingUser(guestToken: string): Observable<void | any> {
    const headers = new HttpHeaders().set('guesttoken', guestToken);
    return this.httpClientWrapper.post(Constants.apiPaths.pingGuest, {}, {headers})
        .pipe(map((obj: any) => obj))
        .pipe(catchError(e => throwError(e)));
  }

  public setLocalVal(state: string | any, keys: string[], values: any[]): void {
    // TODO: Rewrite the logic
    let callState = JSON.parse(localStorage.getItem(Constants.optimyLocalStore) as string) || {};
    if (state) {
      const stateObj = {
        [state]: callState[state] ? callState[state] : {}
      }
      keys.forEach(function (key: string, index: number) {
        stateObj[state][key] = values[index];
      });
      callState = {
        ...callState,
        ...stateObj
      }
    } else {
      keys.forEach(function (key: string, index: number) {
        callState[key] = values[index];
      });
    }
    this.store.dispatch(new appActions.SetLocalStoreInfo(callState));
    localStorage.setItem(Constants.optimyLocalStore, JSON.stringify(callState));
  }

  getLocalVal(state: string, key?: string) {
    if (localStorage.getItem(Constants.optimyLocalStore)) {
      const callState = JSON.parse(localStorage.getItem(Constants.optimyLocalStore) as string);
      if (key && callState[state]) {
        return callState[state][key] ? callState[state][key] : null;
      } else {
        return callState[state];
      }
    }
  }

  getSessionVal(key: string) {
    const state = Constants.optimySessionStore;
    if (sessionStorage.getItem(state)) {
      const callState = JSON.parse(sessionStorage.getItem(state) as string);
      return callState[key];
    }
  }

  setSessionVal(keys: string[], values: any[]) {
    const state = Constants.optimySessionStore;
    const callState = JSON.parse(sessionStorage.getItem(state) as string) || {};
    keys.forEach(function (key, index) {
      callState[key] = values[index];
    });
    this.store.dispatch(new appActions.SetSessionStoreInfo(callState));
    sessionStorage.setItem(state, JSON.stringify(callState));
  }

  removeLocalStoreVal(state: string, keys: string[], values?: string[]) {
    if (localStorage.getItem(Constants.optimyLocalStore)) {
      const callState = JSON.parse(localStorage.getItem(Constants.optimyLocalStore) as string);
      if (values) {
        values.forEach(value => {
          keys.forEach(key => {
            if (callState[key] && callState[key][value]) {
              delete callState[key][value];
            }
          });
        })
      } else {
        keys.forEach(function (key: string) {
          delete callState[key];
        });
      }
      this.store.dispatch(new appActions.SetLocalStoreInfo(callState));
      localStorage.setItem(state, JSON.stringify(callState));
    }
  }

  removeSessionStoreVal(state: string, keys: string[]) {
    if (sessionStorage.getItem(state)) {
      const callState = JSON.parse(sessionStorage.getItem(state) as string);
      keys.forEach(function (key: string) {
        delete callState[key];
      });
      this.store.dispatch(new appActions.SetSessionStoreInfo(callState));
      sessionStorage.setItem(state, JSON.stringify(callState));
    }
  }

  getGuestIdentity(identity: string, user: CreateReq) {
    let guestName;
    if (user.full_name) {
      guestName = `${identity}_${user.full_name.split(' ')[0]}`;
    } else if (user.email) {
      guestName = `${identity}_${user.email}`;
    } else {
      guestName = identity
    }
    return guestName;
  }

  checkAndRedirectToState() {
    if (this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken)
        && this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId)) {
      this.sharedService.checkCurrentState(true);
      return true;
    }
    return false;
  }

  checkAndRedirectToBooking() {
    const agentDetails = this.getSessionVal(AgentStatus.agentStatusInfo);
    if (agentDetails?.booking_config?.booking_url) {
      window.open(agentDetails.booking_config.booking_url, '_blank');
      return true;
    }
    return false;
  }


  setAgentStatusToSessionStorage(res: AgentResponseData) {
    const colors = res?.fab_config?.colors ? res?.fab_config?.colors : [];
    this.setSessionVal([AgentStatus.agentStatusInfo], [res]);
    this.setSessionVal([PluginStates.colorConfig], [colors]);
  }

  public setCobrowse(licenseKey?: string) {
    if (!environment.local) {
      (<any>window).CobrowseIO.license = licenseKey ?
          licenseKey : environment.cobrowseLicense;
      (<any>window).CobrowseIO.trustedOrigins = [location.origin];
      (<any>window).CobrowseIO.client().then(function () {
        (<any>window).CobrowseIO.start();
      });
    }
    let customData =  (<any>window).CobrowseIO.customData;
    customData = {
      ...customData,
      snowplow_domain_userid: this.snowplowService.getSnowplowUserId()
    };
    (<any>window).CobrowseIO.customData = customData;
    this.socketService.joinRoom(customData.snowplow_domain_userid);
  }

  removeSessionStorage() {
    this.removeSessionStoreVal(Constants.optimySessionStore, [RoomInfoStates.queueInviteCode]);
    this.removeSessionStoreVal(Constants.optimySessionStore, [RoomInfoStates.queuePositionId]);
    this.removeSessionStoreVal(Constants.optimySessionStore, [RoomInfoStates.agentName]);
    this.removeSessionStoreVal(Constants.optimySessionStore, [RoomInfoStates.guestToken]);
  }

  setSessionStorage() {
    const roomInformation = this.getLocalVal(PluginStates.roomInfo);
    this.setSessionVal([RoomInfoStates.queuePositionId], [roomInformation?.queuePositionId]);
    this.setSessionVal([RoomInfoStates.agentName], [roomInformation?.agentName]);
    this.setSessionVal([RoomInfoStates.guestToken], [roomInformation?.guestToken]);
  }

  removeExtraParamsFormUrl() {
    //TODO: Please update the function with better logic (this is a quick fix)
    this.sourceURL = location.href;
    QueryParamList.forEach(param => {
      if (this.sourceURL.includes(param)) {
        this.sourceURL = this.removeParams(param, this.sourceURL);
      }
    });
    if (this.sourceURL.includes('#/')) {
      const checkUrlParams = this.sourceURL?.split('#/');
      let finalUrl = checkUrlParams[0];
      if (checkUrlParams[1]) {
        if (checkUrlParams[1].includes('(') || checkUrlParams[1].includes(')')) {
          const splitUrl = checkUrlParams[1]?.split(')');
          finalUrl = checkUrlParams[0]?.concat(splitUrl && splitUrl[1] ? splitUrl[1] : '');
        } else {
          finalUrl = checkUrlParams[0]?.concat(checkUrlParams[1]);
        }
      }
      return finalUrl;
    } else {
      return this.sourceURL;
    }

  }

  removeParams(key: string, sourceURL: string): string {
    //TODO: Please update the function with better logic (this is a quick fix)
    let rtn = sourceURL.split('?')[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      params_arr = queryString.split('&');
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split('=')[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
    }
    return rtn;
  }

  setFormControlsForIframe(form: UntypedFormGroup, inputFields: AllInputFields[], formValues: any, value: boolean) {
    let i = 0;
    while (i < inputFields?.length) {
      const value = inputFields[i].name;
      if (formValues[value]) {
        form.addControl(value, new UntypedFormControl(formValues[value]));
      }
      i++;
    }
    if (value && formValues?.phone && formValues.phone.length === 10 && !formValues.phone.includes('(')) {
      form.controls.phone.setValue(formValues.phone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'));
    }
    return form;
  }

  public buildCustomFieldsObject(attr: any): any {
    const attrCustomFields: any = {};
    const attrNotCustomFields: any = {};
    for (const key in attr) {
      if (key !== UserInfoRequiredField.queue_id && this.checkAttrValue(attr, key)) {
        if (notCustomField.includes(key)) {
          attrNotCustomFields[key] = attr[key].value || attr[key];
        } else {
          attrCustomFields[key] = attr[key].value || attr[key];
        }
      }
    }
    return {...attrNotCustomFields, custom_fields: {...attrCustomFields}};
  }

  checkAttrValue(attr: any, key: string) {
    return typeof attr[key] !== 'object' || (attr[key]?.value && typeof attr[key]?.value !== 'object');
  }

  checkAndGetCurrentLang() {
    const lang = this.getLocalVal(PluginStates.language);
    const currentLocale = navigator.language.toLowerCase();
    if (currentLocale.includes(lang)) {
      return currentLocale;
    }
    return lang;
  }

  checkJoinQueueConfig(fromInviteLink: boolean | any, joinQueueConfig: any) {
    if (joinQueueConfig) {
      const configKeys = Object.keys(joinQueueConfig);
      const objKeys = configKeys ? configKeys.find((key: any) => (key !== 'custom_form')
          && joinQueueConfig[key].visible) : '';
      if (objKeys) {
        return true;
      } else return !!(joinQueueConfig['custom_form']?.pages && !fromInviteLink);
    }
    return false;
  }

  storeValueForUserInfoAndDevice(queue_id: number, tenantid: number) {
    let userInfo: UserInfo = this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.userInfo);
    userInfo = {
      ...userInfo,
      queue_id,
      ip: this.ip ? this.ip : Constants.defaultIp,
      tenantid
    }
    this.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [userInfo]);
    this.setLocalVal(PluginStates.callInfo, [CallInfoStates.audio, CallInfoStates.video], [false, false]);
  }

  isGuestOnCall(): boolean {
    const roomInfo = this.getLocalVal(PluginStates.roomInfo);
    return roomInfo && (roomInfo.roomStatus === CallStatus.in_progress);
  }

  public updateGuestStatus(req: any, guestToken: string) {
    const headers = new HttpHeaders().set('guesttoken', guestToken);
    return this.httpClientWrapper.post(Constants.apiPaths.guestStatusUpdate, req, {headers})
        .pipe(map((obj: any) => obj))
        .pipe(catchError(e => throwError(e)));
  }

  checkAndSetGuestStatus(newStatus: string, guestToken?: string) {
    const currentGuestStatus = this.getLocalVal(PluginStates.guestStatus);
    if (currentGuestStatus !== newStatus && guestToken) {
      const position_id = this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
      let req: any = {
        status: newStatus
      };
      if(newStatus !== GuestStatus.available){
        req['attr'] = JSON.stringify({queue_position_id: position_id});
      }
      this.updateGuestStatus(req, guestToken).pipe().subscribe(() => {
        this.setLocalVal(null, [PluginStates.guestStatus], [newStatus]);
      });
    }
  }

  checkStatusAndRedirect() {
    const agentStatusConfig = this.getSessionVal(AgentStatus.agentStatusInfo) as AgentResponseData;
    if (agentStatusConfig.fab_config?.disable_send_feedback) {
      this.removeSessionStorage();
      this.videoCallService.closePluginWithoutFeedback$.next(true);
    } else {
      setTimeout(() => {
        this.router.navigate([{outlets: {plugin: [RoutesUrls.feedback]}}], { skipLocationChange : true});
      }, 200);
    }
  }

  getCodeFromInviteLink(link: string) {
    let inviteCode = link?.split(InviteCodeParams.optimyCode)[1];
    if (inviteCode?.length !== 32 && inviteCode?.length !== 8) {
      inviteCode = link?.split(InviteCodeParams.code)[1];
    }
    return inviteCode;
  }

  // TODO: remove from here and get from screenOrientation service
  isOnMobile(): boolean {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  getDomainSessionId() {
    return document.cookie.split('; ').filter(subCookies => subCookies.indexOf('_sp_id') >= 0).join('').split('=').filter(subCookies => subCookies.indexOf('_sp_id') < 0).join('').split('.')[5];
  }

  saveInfoForCobrowse() {
    const roomInfo = this.getLocalVal(PluginStates.roomInfo);
    if (roomInfo) {
      this.setSessionVal([RoomInfoStates.guestId], [roomInfo?.guestId]);
      this.setSessionVal([RoomInfoStates.roomName], [roomInfo?.roomName]);
    }
  }

  getTimeInEpoxy(inSeconds: boolean): number {
    return inSeconds ? moment(new Date(), 'LT').unix() : moment(new Date(), 'LT').valueOf();
  }

  updateBrowserTitle(isMessage: boolean, reset: boolean) {
    const guestStatus = this.getLocalVal(PluginStates.guestStatus);
    const callInfo: CallInfo = this.getLocalVal(PluginStates.callInfo);
    const queueId = this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId) || '';
    if (reset && this.messageCount && ((guestStatus === GuestStatus.inQueue || guestStatus === GuestStatus.available) ||
        (guestStatus === GuestStatus.inCall && callInfo && callInfo.chat))) {
      document.title = this.originalTittle;
      this.snowplowService.trackStructEvent(this.spTracker.categories.tabActivity,
          this.spTracker.actions.titleChangeEnd, this.spTracker.labels.title, this.spTracker.properties.callId, queueId, this.getCallSchemaContext());
      this.titleChangeEnded = true
      this.messageCount = 0;
      clearInterval(this.titleChangeInterval);
    } else {
      if (!isMessage && document.hidden) {
        this.messageCount++;
        this.animateBrowserTitle();
        if (this.titleChangeEnded) {
          this.snowplowService.trackStructEvent(this.spTracker.categories.tabActivity,
              this.spTracker.actions.titleChangeStart, this.spTracker.labels.title, this.spTracker.properties.callId, queueId, this.getCallSchemaContext());
        }
        this.titleChangeEnded = false;
      }
    }
  }

  animateBrowserTitle() {
    clearInterval(this.titleChangeInterval);
    const messageText = this.messageCount > 1 ? this.translate.instant('common.newMessages') :
        this.translate.instant('common.newMessage');
    this.titleChangeInterval = setInterval(() => {
      document.title = this.currentTitleState ? `(${this.messageCount}) ${this.originalTittle}` : `${this.messageCount} ${messageText}`;
      this.currentTitleState = !this.currentTitleState;
    }, 2000);
  }

  playAlertAudio() {
    const queueId = this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId) || '';
    if (!this.audioPlayed) {
      this.soundMp3.play().then(() => {
        this.snowplowService.trackStructEvent(this.spTracker.categories.tabActivity,
            this.spTracker.actions.soundPlay, this.spTracker.labels.sound, this.spTracker.properties.callId, queueId, this.getCallSchemaContext());
      }).catch(() => {
        this.snowplowService.trackStructEvent(this.spTracker.categories.tabActivity,
            this.spTracker.actions.soundBlock, this.spTracker.labels.sound, this.spTracker.properties.callId, queueId, this.getCallSchemaContext());
      });
      this.audioPlayed = true;
      setTimeout(() => {
        this.audioPlayed = false;
      }, 5000);
    }
  }

  getCallSchemaContext(queuePositionId?: number): Context[] | [] {
    const callId = queuePositionId ?? this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
    if(callId){
      return [{
        schema: this.spTracker.schemas.callSchema,
        data: {
          id: callId
        }
      }]
    }
    return [];
  }

  reJoinAllTheRooms() {
    const tenantId = this.getSessionVal(AgentStatus.agentStatusInfo)?.tenant_id;
    const domainSesId = this.snowplowService.getSnowplowUserId();
    const roomName = this.getLocalVal(PluginStates.roomInfo, RoomInfoStates.roomName);
    const rooms = roomName ? [`guest${tenantId}`, domainSesId, roomName] : [`guest${tenantId}`, domainSesId];
    this.socketService.joinRoom(rooms);
  }

  createGuestReqValues(id?: number) {
    const userInfo = this.getLocalVal(PluginStates.roomInfo)?.userInfo;
    let req: any = {};
    if (userInfo) {
      Object.keys(userInfo).forEach((element: any) => {
        if (GuestCreateDefaultFields.includes(element)) {
          req[element] = element === UserInfoRequiredField.phone ?
              `${userInfo[element].replace(/-|\s/g,'')}` : userInfo[element];
        }
      });
    }
    req = {
      ...req,
      user_agent: `${navigator.userAgent}&tenantid=${userInfo?.tenantid ?? id}`,
      client_user_id: this.snowplowService.getSnowplowUserId(),
      full_name: userInfo?.full_name ?? null
    };
    return req;
  }

  checkRoutingEnabledAndRoomStatus() {
    const roomInfo = this.getLocalVal(PluginStates.roomInfo);
    const agentDetails = this.getSessionVal(AgentStatus.agentStatusInfo);
    return agentDetails?.join_queue_config?.routing_enabled && roomInfo?.roomStatus === CallStatus.routing;
  }

  getJoinQueueQuestionSchema(): Context | any {
    const joinQueueConfig = this.getSessionVal(AgentStatus.agentStatusInfo)?.join_queue_config;
    if(joinQueueConfig?.join_queue_config_version){
      return {
        schema: this.spTracker.schemas.joinQueueQuestionSchema,
        data: {
          config_id: +joinQueueConfig?.join_queue_config_version
        }
      }
    }
    return;
  }

  setLastCallStamp(){
    this.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastCallTimeStamp], [moment().utc()]);
  }

  getAndCheckLastCallStamp(lastCallTime: string){
    const callStampDiff = moment().utc()?.diff(moment(lastCallTime).utc(), 'minutes');
    return callStampDiff <= 5;
  }

   getAgentStatusFormRedisCaChe(): Observable<AgentResponseData | any> {
    const headers = new HttpHeaders().set('licensekey', this.licenseKey);
     const sessionId: string = this.snowplowService.getSnowplowSessionId();
    return this.httpClientWrapper.post(Constants.apiPaths.getLatestAgentStatusInfo, {env: environment.environmentName, domainSessionId: sessionId}, { headers })
        .pipe(map((response: any) => {
          const agentStatus = response?.data;
            if (agentStatus?.length) {
              const getSessionData: AgentStatusCacheValues = this.getSessionVal(AgentStatus.agentStatusCacheData);
              if (getSessionData && Object.keys(getSessionData)?.includes(sessionId)) {
                if (agentStatus.length === getSessionData[sessionId]?.num_options) {
                  return agentStatus[getSessionData[sessionId]?.option_index];
                } else {
                  this.removeSessionStoreVal(Constants.optimySessionStore, [AgentStatus.agentStatusCacheData]);
                  return this.doFormatForAgentData(sessionId, agentStatus);
                }
              } else {
                return this.doFormatForAgentData(sessionId, agentStatus);
              }
            } else if (agentStatus){
              return agentStatus;
            }
        }))
    .pipe(
        catchError((err) => {
          return throwError(err);
        }))
  }

  doFormatForAgentData(sessionId: string, obj: any){
    let hash = Md5.hashStr(`${sessionId}`);
    const decimalStr = parseInt(hash, 16);
    const option_index = decimalStr % obj?.length;
    if(option_index >= 0 && option_index < obj?.length){
      let agentStatusValue: AgentStatusCacheValues = {
        [sessionId]: {
          num_options: obj?.length,
          option_index: option_index
        }
      };
      this.setSessionVal([AgentStatus.agentStatusCacheData], [agentStatusValue]);
      return obj[option_index];
    }
    return;
  }

  getTextFromCurrentLanguage(text: { [key in string]: string } | undefined, lang: string): string {
    if (!text) return '';
    return text[lang] || text.en;
  }

  getChoices(choices: BubbleChoice[] | undefined, lang: string, booking_url: string): CustomButton[] {
    const customButtons: CustomButton[] = [];
    choices?.forEach((choice: BubbleChoice) => {
      let button: any = {
        text: this.getTextFromCurrentLanguage(choice.text, lang),
        type: choice.type || 'stroked'
      };
      if (choice.action) {
        button.action = choice.action;
        if(button.action === RedirectAction.redirect && choice?.url){
          button.url = choice.url;
        } else if(button.action === TimeExceedAction.booking && booking_url){
          button.url = booking_url;
        }
      }
      if (choice.queue_id) {
        button.queue_id = choice.queue_id;
      }
      if (choice.skip_to_question || choice.skip_to_question === 0) {
        button.skip_to_question = choice.skip_to_question;
      }
      if(choice?.skip_to_question_fail){
        button.skip_to_question_fail = choice.skip_to_question_fail;
      }
      customButtons.push(button);
    });
    return customButtons;
  }

  checkAndSetEvent(): EventKeys {
    const agentStatusInfo =  this.getSessionVal(AgentStatus.agentStatusInfo);
    const index = this.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.currentScreenIndex);
    const temp = agentStatusInfo?.join_queue_config?.screens;
    const tempName = index === -1 ? QueueTemplates.leaveMessage : temp[index];
    switch (tempName) {
      case QueueTemplates.start: {
        return {
          label: agentStatusInfo?.are_agents_online ? this.spTracker?.labels.startScreenOnline : this.spTracker?.labels.startScreenOffline,
          property: ''
        }
      }
      case QueueTemplates.leaveMessage: {
        return {
          label: this.spTracker?.labels.leaveMsg,
          property: ''
        }
      }
      case QueueTemplates.contact: {
        const contactOptSelected = this.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.contactFormSelectionOpt);
        return {
          label: contactOptSelected === CallDropOptions.noThanks ? this.spTracker?.labels.contactFormNoPhone : this.spTracker?.labels.contactFormPhone,
          property: ''
        }
      }
      case QueueTemplates.questions: {
        const questionIndex = this.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.currentQuestionIndex);
        const property = agentStatusInfo?.join_queue_config?.queue_wait_questions[questionIndex]?.event_key;
        return {
          label: questionIndex > 0 ? this.spTracker?.labels.questionsScreenNext : this.spTracker?.labels.questionsScreenFirst,
          property: property
        }
      }
      case QueueTemplates.waiting: {
        return {
          label: this.getWaitScreenLabel(),
          property: ''
        }
      }
      default :
        return {
          label: agentStatusInfo?.are_agents_online ? this.spTracker?.labels.startScreenOnline : this.spTracker?.labels.startScreenOffline,
          property: ''
        }
    }
  }

  getWaitScreenLabel(): string {
    const templateInStore = this.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.waitScreenTemplate);
    if (templateInStore === WaitTemplate.waitAgentJoin) {
      return this.spTracker.labels.mainQueueAgent;
    } else if (templateInStore === WaitTemplate.waitNoShowWithMobile) {
      return this.spTracker.labels.mainQueueTimeoutText;
    } else if (templateInStore === WaitTemplate.waitNoShowWithoutMobile) {
      return this.spTracker.labels.mainQueueTimeoutNoText;
    } else {
      return this.spTracker.labels.mainQueue;
    }
  }
}
