import { LoggingService } from '../../core/services/logging/logging.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { firstValueFrom, forkJoin, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentResponseData, AgentStatus } from '../../core/constants/agent.modal';
import {
  CallStatus,
  CallStatusInfo,
  CallStatusWithInformation,
  CreateGuestRes,
  CreateReq,
  JoinQueueInterface,
  OutbounRejectReq,
  Queue,
  RouteSate
} from '../../core/constants/call.modal';
import {
  CallInfoStates,
  Context,
  GuestStatus,
  InviteData,
  InviteLinkStatus,
  PluginStates,
  RoomCreatedData,
  RoomData,
  RoomInfo,
  RoomInfoStates,
  RoutesUrls,
  updateScheduleStatus,
  UserInfo
} from '../../core/constants/common.enum';
import { Constants } from '../../core/constants/constant';
import {
  GtmTrackers,
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels,
  SnowplowTrackerProperties,
  SnowplowTrackerSchemas
} from '../../core/constants/trackerLabels';
import { ChatService } from '../../core/services/chat/chat.service';
import { RecordingService } from '../../core/services/recording/recording.service';
import { SharedService } from '../../core/services/shared/shared.service';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { UtilsService } from '../../core/services/utils/utils.service';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { GtmService } from '../../core/services/gtm/gtm.service';
import { MessageCreateReq } from '../../core/constants/chat.modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('4s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class VideoCallComponent implements OnInit, OnDestroy {
  @ViewChild('messagePanel') messagePanel: ElementRef;

  fillerUrl = 'https://dx9457ojp328v.cloudfront.net/icons/waiting-animated-three-dots.svg';
  imageUrl = Constants.imagePaths;
  isUserInQueue: boolean;
  isUserInCall: boolean;
  isUserInStatus = false;
  callStatus = CallStatus;
  roomInfo: RoomInfo;
  queue: Queue;
  closedDate: string;
  roomStatus: string;
  routeState: string;
  userInfo: UserInfo;
  guestUserId: string;
  guestToken: string;
  roomName: string;
  inviteLink: string;
  queuePositionId: number;
  language: string;
  isOutboundCall: boolean;
  isMinimized = false;
  memberAdded = false;
  isLinkExpired: boolean;
  callStatusInfo: CallStatusInfo | any;
  positionOnQueue: number;
  pluginConfigs: AgentResponseData;
  showBookingButton = true;
  showLoader: boolean;
  callStart = false;
  isLeaveForm = false;
  joinQueueRes: JoinQueueInterface;
  stopTimer = false;
  agentDetails: AgentResponseData;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties,
    schemas: SnowplowTrackerSchemas
  };

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private videoCallService: VideoCallService,
    private chatService: ChatService,
    private socketService: SocketService,
    private recordingService: RecordingService,
    private sharedService: SharedService,
    private snowplowService: SnowplowService,
    private loggingService: LoggingService,
    private router: Router,
    private gtmService: GtmService,
    private translate: TranslateService) {

    this.socketService.roomData
      .pipe(takeUntil((this.destroy$)))
      .subscribe({
        next: (data: RoomData) => {
          if (+data.queue_position_id === this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId)) {
            this.roomName = data?.room_name;
            this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
            if (this.roomInfo.roomStatus === CallStatus.ad_hoc
              || this.roomInfo.roomStatus === CallStatus.scheduled) {
              this.createMember();
            }
            this.setCoBrowseCustomData();
            this.isUserInCall = true;
            this.gtmService.sendGtm(GtmTrackers.optimyCallStarted);
            if(data?.room_status === CallStatus.in_progress){
              this.sharedService.agentJoiningCall$.next(true);
            }
          }
        }
      });

    this.socketService.roomCreated
        .pipe(takeUntil((this.destroy$)))
        .subscribe({
          next: (data: RoomCreatedData) => {
            if(data?.room_name === this.roomName && data?.starting && !data?.sendFromSmallRoom){
              this.sharedService.agentJoiningVideoCall$.next(data);
            }
          }
        });

    this.sharedService.disconnectCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closePlugin();
        this.ngOnDestroy();
      });
    this.sharedService.closeCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closePlugin();
      });

    this.sharedService.updatedAgentInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: (data: AgentResponseData) => {
            this.pluginConfigs = data;
            this.showBookingButton = !this.pluginConfigs?.booking_config?.defaults.disabled;
          },
        });
  }

  ngOnInit(): void {
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.pluginConfigs = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.language = this.utils.getLocalVal(PluginStates.language);
    this.joinQueueRes = this.pluginConfigs?.join_queue_config as JoinQueueInterface;
    this.isUserInQueue = false;
    this.sharedService.hideHeaderAndFooter(true);
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.userInfo = this.roomInfo?.userInfo;
    this.roomStatus = this.roomInfo?.roomStatus;
    this.routeState = this.roomInfo?.routeState;
    this.roomName = this.roomInfo?.roomName;
    this.guestToken = this.roomInfo?.guestToken;
    this.queuePositionId = this.roomInfo?.queuePositionId;
    this.callStatusInfo = CallStatusWithInformation.find((callStatus: CallStatusInfo) => this.roomInfo?.roomStatus === callStatus.status);
    this.showBookingButton = !this.pluginConfigs?.booking_config?.defaults.disabled;
    this.isOutboundCall = !!this.roomInfo?.isOutboundCall;
    this.closedDate = moment.unix(this.roomInfo?.closeDt).format('MM/DD/YYYY hh:mm:ss A ');
    this.loadRoom();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public outboundCallAnswer(accepted: boolean) {
    this.sharedService.sendOutboundCall(accepted);
    if (accepted) {
      this.videoCallService.acceptOutbound(this.guestToken, this.roomName).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isOutboundCall = false;
      });
    } else {
      const req: OutbounRejectReq = {
        room_name: this.roomName,
        auto_reject: 0
      }
      this.videoCallService.rejectedOutbound(this.guestToken, req).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.videoCallService.closePluginWithoutFeedback$.next(true);
      });
    }
    this.clearLocalStorageVariablesForOutboundCall();
  }

  private clearLocalStorageVariablesForOutboundCall(): void {
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo], [RoomInfoStates.isOutboundCall]);
  }

  loadRoom() {
    if (this.routeState === RouteSate.onGoing) {
      this.utils.setLastCallStamp();
      this.pingGuest();
      this.isUserInQueue = false;
      this.isUserInCall = true;
      if(!this.roomInfo?.guestToken){
        this.utils.storeValueForUserInfoAndDevice(this.agentDetails?.default_queue_id, this.agentDetails?.tenant_id);
        this.createChannelGuest();
      }
      this.setCoBrowseCustomData();
    } else if (this.routeState === RouteSate.waiting) {
      this.isUserInQueue = true;
      this.isUserInCall = false;
      this.pingGuest();
      if(!this.roomInfo?.guestToken){
        this.utils.storeValueForUserInfoAndDevice(this.agentDetails?.default_queue_id, this.agentDetails?.tenant_id);
        this.createChannelGuest();
      }
      this.setCoBrowseCustomData();
    } else if (this.routeState === RouteSate.completed) {
      this.isUserInStatus = true;
      this.isUserInQueue = false;
      if (this.roomInfo?.roomStatus === this.callStatus.pending || this.roomInfo?.roomStatus === this.callStatus.ad_hoc) {
        this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, this.guestToken);
      } else {
        this.utils.checkAndSetGuestStatus(GuestStatus.available);
      }
    } else {
      this.createChannelGuest();
    }
  }

  pingGuest() {
    this.pingFn();
    interval(10000).pipe(takeUntil(this.destroy$)).subscribe(() => {
      const token = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
      if (token) {
        this.pingFn();
      }
    });
  }

  pingFn() {
    this.utils.pingUser(this.guestToken).pipe(takeUntil(this.destroy$)).subscribe(() => {
      console.log('ping success');
    }, (e) => {
      if (e.status === 401 || e.status === 403) {
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo], [RoomInfoStates.guestToken]);
      }
    });
  }

  createChannelGuest() {
    let req: CreateReq = this.utils.createGuestReqValues();
    this.videoCallService.createGuest(req).pipe(takeUntil(this.destroy$)).subscribe((res: CreateGuestRes) => {
      this.guestUserId = this.setGuestUserId(res?.id);
      this.guestToken = res?.guesttoken;
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.guestToken], [this.guestToken]);
      if (this.roomStatus === CallStatus.pending || this.roomStatus === CallStatus.ad_hoc) {
        const invitationCode = this.utils.getCodeFromInviteLink(this.roomInfo?.inviteLink);
        this.videoCallService.getRoomFromInvite(invitationCode).subscribe((res) => {
          this.isLinkExpired = false;
          if (res?.status === CallStatus.in_progress || res?.status === CallStatus.pending) {
            this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastCallTimeStamp], [moment().utc()]);
            this.createMember();
          } else {
            this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, this.guestToken);
            this.isUserInStatus = true;
          }
        }, error => {
          console.log('error with invite read', error);
          if (error.error.includes(InviteLinkStatus.expired)) {
            this.isLinkExpired = true;
            this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo, PluginStates.callInfo]);
          } else {
            this.routeToAppLauncher();
          }
        });
      } else if (this.roomInfo.isOutboundCall && this.roomInfo.queue_invite_code) {
        this.initializeOutboundCall(this.roomInfo.queue_invite_code);
      } else if (this.roomName) {
        this.setCoBrowseCustomData();
        this.createMember();
      } else {
        this.addUserToQueue();
      }
    });
    this.pingGuest();
  }

  initializeOutboundCall(queueInviteCode: string) {
    if (this.roomName) {
      this.createMemberOutboundCall();
    } else {
      this.videoCallService.getRoomFromInvite(queueInviteCode).pipe(takeUntil(this.destroy$)).subscribe((res: InviteData) => {
        this.roomName = res.room_name;
        this.roomStatus = res.status;
        this.utils.setLocalVal(
          PluginStates.roomInfo,
          [RoomInfoStates.roomName, RoomInfoStates.roomStatus, RoomInfoStates.inviteLink, RoomInfoStates.queuePositionId],
          [res.room_name, res.status, res?.guest_link, res?.queue_position_id]
        );
        this.utils.checkAndSetGuestStatus(GuestStatus.inCall, this.guestToken);
        this.createMemberOutboundCall();
      }, error => {
        console.log('error with invite read', error);
        this.routeToAppLauncher();
      });
    }
  }

  routeToAppLauncher() {
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo]);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], { skipLocationChange : true});
  }

  createMemberOutboundCall() {
    const attributes = {
      ...this.userInfo,
      cameraOff: true,
      micOff: true,
      isOffline: false
    };
    this.setCoBrowseCustomData();
    this.createMember(attributes);
  }

  createMember(attributes?: any) {
    if (!this.memberAdded) {
      this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
      this.memberAdded = true;
      if (!attributes) {
        attributes = {
          ...this.userInfo,
          cameraOff: true,
          micOff: true,
          isOffline: false,
        };
      }
      attributes.domain_userid = this.snowplowService.getSnowplowUserId();
      const guestId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
      this.chatService.createMember(guestId, this.roomName, attributes).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        const req: MessageCreateReq = {
          channelSid: this.roomName,
          message: this.translate.instant('inCall.chat.joinMessage', {userName: this.guestUserId?.split('_')[1]}),
          identity: 'system',
        };
        const sendMemberUpdateSocket = this.chatService.memberUpdated(this.roomName);
        const createGuestJoinMessage = this.chatService.sendChatMessages(req);
        forkJoin([sendMemberUpdateSocket, createGuestJoinMessage]).subscribe(
            () => {
              console.log('member updated successfully');
              this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.memberSid], [res?.sid]);
              return this.startCall();
            });
      }, (() => {
        console.log('error while creating member');
        return this.startCall();
      }));
    }
  }

  startCall() {
    this.sharedService.showAppLauncher(false);
    if (this.utils.getLocalVal(PluginStates.callInfo, CallInfoStates.isWindowMinimized)) {
      this.sharedService.minimizeApp(false);
    }
    this.isUserInCall = true;
    this.isUserInQueue = false;
    this.isUserInStatus = false;
    this.sharedService.checkCurrentState(false);
    if (this.isOutboundCall) {
      this.sharedService.agentJoiningCall$.next(true);
    }
  }

  addUserToQueue() {
    this.showLoader = true;
    this.videoCallService.createChannel().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      let attr: any = {
        room_name: res?.sid,
        guest_hostname: this.utils.removeExtraParamsFormUrl(),
        lang: this.language,
        guest_locale: navigator.language,
        domain_sessionid: this.utils.getDomainSessionId(),
        num_customers_in_queue: this.pluginConfigs.num_customers_in_queue,
        num_agents_available_now: this.pluginConfigs.agents_available_now,
        domain_userid: this.snowplowService.getSnowplowUserId()
      };
      this.roomName = res?.sid;
      attr = { ...attr, custom_fields: this.userInfo.custom_fields };
      let roomName: any = { attr: JSON.stringify(attr) };
      if (this.agentDetails?.join_queue_config?.routing_enabled || this.agentDetails?.join_queue_config?.text_during_routing) {
        roomName.status = CallStatus.routing;
        this.utils.setLocalVal(
          PluginStates.roomInfo,
          [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
          [CallStatus.routing, RouteSate.waiting]
        );
        this.sharedService.enteredRouting(true);
      } else {
        this.utils.setLocalVal(
            PluginStates.roomInfo,
            [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
            [CallStatus.pending, RouteSate.waiting]
        );
      }
      this.roomName = res?.sid;
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.roomName], [res?.sid]);
      this.videoCallService.addUserToQueue(this.guestToken, this.userInfo?.queue_id, roomName).subscribe((queueResponse: Queue) => {
        if(queueResponse?.status === CallStatus.pending) {
          this.gtmService.sendGtm(GtmTrackers.optimyJoinQueue);
        }
        this.setCoBrowseCustomData();
        this.showLoader = false;
        this.sharedService.userJoinedQueue(true);
        // this.showInitialPanel(loaderTime, queueResponse.id);
        this.queue = queueResponse;
        this.socketService.joinRoom(this.queue?.room_name);
        // this.createdTime = queueResponse.created;
        this.positionOnQueue = queueResponse.position_index;
        const code = this.utils.getCodeFromInviteLink(queueResponse?.guest_link);
        this.utils.setSessionVal([RoomInfoStates.queueInviteCode], [code]);
        // this.timeLeft = this.utils.getMinutesLeft(new Date(this.queue.estimated_start * 1000));
        this.isUserInQueue = true;
        this.isUserInCall = false;
        this.inviteLink = this.queue.guest_link;
        this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.inviteLink, RoomInfoStates.lastCallTimeStamp], [this.inviteLink, moment().utc()]);
        // this.setTimerAndCheckForTimeLeft();
        this.sharedService.setTimeLeft(this.utils.getMinutesLeft(new Date(this.queue.estimated_start * 1000)));
        this.queuePositionId = this.queue?.id;
        this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.queuePositionId], [this.queue?.id]);
        this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, this.guestToken);
        this.createMember();
      });
    });
  }

  setCoBrowseCustomData() {
    let customData = (<any>window).CobrowseIO.customData;
    this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
    customData = {
      ...customData,
      user_id: this.guestUserId
    };
    customData.snowplow_domain_userid = this.snowplowService.getSnowplowUserId();
    if(this.roomName) {
      customData.room_name = this.roomName;
    }
    if (this.userInfo?.full_name) {
      customData.user_name = this.userInfo.full_name
    }
    (<any>window).CobrowseIO.customData = customData;
  }

  updateClass(j: number) {
    this.messagePanel.nativeElement.querySelectorAll('.op-btn-secondary-trans').forEach((btn: Element, i: number) => {
      if (i === j) {
        btn.classList.add('selected');
      } else {
        btn.classList.add('disabled');
      }
    });
  }


  getPositionSuffix(position: number): string {
    const strPosition = (position + '').toString().slice((position).toString().length - 1);
    switch (strPosition) {
      case '1':
        return 'st';
      case '2':
        return 'nd';
      case '3':
        return 'rd';
      default:
        return 'th';
    }
  }

  setGuestUserId(id: string) {
    let guestName;
    if (this.userInfo?.full_name) {
      guestName = `${id}_${this.userInfo?.full_name.split(' ')[0]}`;
    } else {
      guestName = id;
    }
    this.guestUserId = guestName;
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.guestId], [guestName]);
    this.loggingService.addIdentityToLogRocket(this.guestUserId);
    return guestName;
  }

  resizeWindow() {
    this.isMinimized = !this.isMinimized;
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue,
      this.isMinimized ? this.spTracker.labels.minimizeQueue : this.spTracker.labels.maximizeQueue,
      this.isMinimized ? this.spTracker.labels.mainQueue : this.spTracker.labels.miniQueue);
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue,
      this.spTracker.labels.screenShown,
      this.isMinimized ? this.spTracker.labels.miniQueue :
        this.spTracker.labels.mainQueue, this.spTracker.labels.version, 1);
  }

  async closePlugin() {
    if (!this.queuePositionId) {
      this.queuePositionId = Number(this.utils.getSessionVal(RoomInfoStates.queuePositionId));
    }
    const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo) as RoomInfo;
    if (this.isSupposedToSetAsMissedCall(roomInfo?.roomStatus)) {
      const bodyParams: any = { status: updateScheduleStatus.missed };
      this.gtmService.sendGtm(GtmTrackers.optimyLeftQueue);
      await firstValueFrom(this.videoCallService.queuePositionUpdate(this.queuePositionId, this.guestToken, bodyParams));
    }
    this.sharedService.hideHeaderAndFooter(false);
    this.recordingService.stopRecordInteractionAction$.next(true);
  }

  private isSupposedToSetAsMissedCall(callStatus: CallStatus | undefined): boolean {
    return !callStatus || callStatus === CallStatus.routing || callStatus === CallStatus.pending;
  }

  @HostListener('window:beforeunload')
  TriggerPing() {
    this.utils.setLastCallStamp();
    const token = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
    if (token) {
      this.pingFn();
    }
    const context: Context[] = [{
      schema: this.spTracker.schemas.callSchema,
      data: { id: this.queuePositionId }
    },
    {
      schema: this.spTracker.schemas.agentSchema,
      data: {
        online_count: this.agentDetails.are_agents_online,
        available_count: this.agentDetails.agents_available_now
      }
    }]
    this.snowplowService.trackStructEvent(this.spTracker.labels.ping, this.spTracker.labels.sent,
      this.spTracker.labels.manualPing, this.spTracker.labels.callId, this.queuePositionId, context);
  }

}
