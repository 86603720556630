<div class="leave-form-container op-my-3 op-d-flex op-flex-column op-justify-content-between">
    <div class="title" [ngClass]="{'hide': activeSection === 3}" >
        <span *ngIf="activeSection === 1" [innerHTML]="'leaveMessage.leaveMessageTitle' | translate"></span>
        <span *ngIf="activeSection === 2" [innerHTML]="'leaveMessage.leaveMessageTitle2' | translate"></span>
    </div>
    <ngx-spinner [fullScreen]="false" type="ball-pulse-sync" size="default"></ngx-spinner>
    <form [formGroup]="leaveMessageForm">
        <div class="op-d-flex op-flex-column op-justify-content-between leave-form">
            <ng-container *ngIf="activeSection === 1">
                <div class="op-d-flex op-flex-column op-justify-content-start">
                    <div class="form-field op-mt-3">
                        <label [innerHTML]="'leaveMessage.name' | translate"></label>
                        <input formControlName="name" [placeholder]="'leaveMessage.name' | translate" maxlength="100">
                        <small class="text-danger"
                               *ngIf="leaveMessageForm.get('name')?.errors &&
                                       leaveMessageForm.get('name')?.invalid &&
                                       leaveMessageForm.get('name')?.touched" [innerHTML]="'leaveMessage.nameError' | translate">
                        </small>
                    </div>
                    <div class="form-field op-mt-3"  >
                        <label [innerHTML]="'leaveMessage.email' | translate"></label>
                        <input formControlName="email" [placeholder]="'leaveMessage.email' | translate" maxlength="250">
                        <small class="text-danger"
                               *ngIf="leaveMessageForm.get('email')?.errors &&
                                       leaveMessageForm.get('email')?.invalid &&
                                       leaveMessageForm.get('email')?.touched"
                               [innerHTML]="'leaveMessage.emailError' | translate">
                        </small>
                    </div>
                    <div class="form-field op-mt-3">
                        <label [innerHTML]="'leaveMessage.mobileNumber' | translate"></label>
                        <div class="intel-phone">
                            <app-intel-tell-input (sendPhoneNumber)="setPhoneNumberValue($event)"></app-intel-tell-input>
                        </div>
                    </div>
                </div>
                <div class="op-d-flex op-flex-column">
                    <span class="text-danger op-mb-2" [innerHTML]="'leaveMessage.emailPhoneError' | translate"
                          *ngIf="showEmailPhoneError"></span>
                    <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1"
                            [disabled]="leaveMessageForm.invalid" (click)="checkValidationAndSend()"
                            [innerHTML]="'intermediateScreen.next' | translate"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="activeSection === 2">
                <div class="op-d-flex op-flex-column op-justify-content-start">
                    <div class="form-field">
                        <textarea formControlName="message" maxlength="500" [placeholder]="'inCall.typeMessage' | translate"></textarea>
                    </div>
                </div>
                <div class="op-d-flex op-flex-column">
                    <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1"
                            [disabled]="leaveMessageForm.invalid" (click)="executeSubmit()"
                            [innerHTML]="'noAgentModal.leaveMessage' | translate"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="activeSection === 3">
                <div class="op-d-flex op-flex-column op-justify-content-start confirmation op-align-items-center">
                    <h6 [innerHTML]="'leaveMessage.getBackYou' | translate"></h6>
                    <svg-icon name="confirmation-message"></svg-icon>
                </div>
                <div class="op-d-flex op-flex-column">
                    <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1" (click)="closeLeaveMessage()"
                            [innerHTML]="'common.close' | translate"></button>
                </div>
            </ng-container>
        </div>
    </form>
</div>
