import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { VideoCallService } from '../../../core/services/video-call/video-call.service';
import { ScreenOrientationService } from '../../../core/services/utils/screen-orientation.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import { ResizeService } from '../../../core/services/resize/resize.service';
import { GtmService } from '../../../core/services/gtm/gtm.service';
import { Constants } from '../../../core/constants/constant';
import {
  ActiveScreen, AutoQueueInfoStates,
  Context,
  PluginStates,
  QueueUpdateReq,
  RoomInfoStates,
  RoutesUrls
} from '../../../core/constants/common.enum';
import {
  GtmTrackers,
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels, SnowplowTrackerProperties
} from '../../../core/constants/trackerLabels';
import { AgentStatus } from '../../../core/constants/agent.modal';
import { CallStatus, CreateReq } from '../../../core/constants/call.modal';
import { SharedService } from '../../../core/services/shared/shared.service';

@Component({
  selector: 'app-leave-message',
  templateUrl: './leave-message.component.html',
  styleUrls: ['./leave-message.component.scss']
})
export class LeaveMessageComponent implements OnInit, OnDestroy {
  @Input() queueId: number;

  activeSection = 1;
  context: Context[];
  emailValidator = [Validators.pattern(Constants.emailRegex)];
  guestToken: string;
  leaveMessageForm: UntypedFormGroup;
  maximize = false;
  queuePositionId: number;
  showEmailPhoneError = false;
  spTrackerLabels = Constants.spTrackerLabels;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: UntypedFormBuilder,
              private gtmService: GtmService,
              private resizeService: ResizeService,
              private router: Router,
              private screenOrientationService: ScreenOrientationService,
              private snowplowService: SnowplowService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private utils: UtilsService,
              private videoCallService: VideoCallService) { }

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.setActiveScreen();
    this.guestToken = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
    this.queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
    this.context = this.utils.getCallSchemaContext();
    const joinQueueQuestionSchema = this.utils.getJoinQueueQuestionSchema();
    if(joinQueueQuestionSchema){
      this.context = [...this.context, joinQueueQuestionSchema];
    }
    this.leaveMessageForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl(''),
      email: new UntypedFormControl('', this.emailValidator),
      message: new UntypedFormControl('')
    });
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.screenShown,
        this.spTracker.labels.leaveMsg, this.spTracker.labels.version, 2, this.context);

    this.leaveMessageForm.valueChanges
        .pipe(takeUntil(this.destroy$), distinctUntilChanged())
        .subscribe((value) => {
          if (value.email || value.phone) {
            this.showEmailPhoneError = false;
          }
        });
    this.gtmService.sendGtm(GtmTrackers.optimyLeaveMessageStarted);
  }

  setActiveScreen() {
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.activeScreenForHeader], [this.activeSection === 2 ? ActiveScreen.leaveMessage : ''])
  }

  checkValidationAndSend() {
    if (this.leaveMessageForm.value.email || this.leaveMessageForm.value.phone) {
      this.activeSection = 2;
      this.setActiveScreen();
    } else {
      this.showEmailPhoneError = true;
    }
  }

  setPhoneNumberValue(value: string){
    this.leaveMessageForm.get('phone')?.setValue(value);
  }

  executeSubmit() {
    const phone = this.leaveMessageForm.get('phone')?.value?.replace(/[-]|[ ]/g, '');
    const attr: QueueUpdateReq = {
      guest_hostname: this.utils.removeExtraParamsFormUrl(),
      lang: this.utils.getLocalVal(PluginStates.language),
      guest_locale: navigator.language,
      domain_sessionid: this.utils.getDomainSessionId(),
      customer_contact :  {
        full_name: this.leaveMessageForm.get('name')?.value,
        phone: phone,
        email: this.leaveMessageForm.get('email')?.value
      },
      comment: this.leaveMessageForm.get('message')?.value,
      message_left: 1
    }
    console.log(attr);
    if(this.queueId && !this.guestToken) {
      this.addMissCallQueue(attr);
    } else {
      this.submitForm(JSON.stringify(attr));
    }
  }

  addMissCallQueue(attr: any) {
    this.spinner.show();
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    let req: CreateReq = this.utils.createGuestReqValues(agentDetails?.tenant_id);
    req = {
      ...req,
      tenantid: agentDetails?.tenant_id
    }
    const queueIdFromLocal = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queueId);
    const queueId = queueIdFromLocal ? queueIdFromLocal : this.queueId;
    const createGuest =  this.videoCallService.createGuest(req)
    const createChannel = this.videoCallService.createChannel();
    forkJoin([createGuest, createChannel]).subscribe((res) => {
      const updatedAttr = {
        ...attr,
        room_name: res[1]?.sid
      }
      const positionAddReq = {
        attr: JSON.stringify(updatedAttr),
        status: CallStatus.missed,
      }
      this.videoCallService.addUserToQueue(res[0]?.guesttoken, queueId, positionAddReq).subscribe(()=> {
        this.gtmService.sendGtm(GtmTrackers.optimyLeaveMessageCompleted);
        this.spinner.hide();
        this.activeSection = 3;
        this.setActiveScreen();
      })
    });
  }

  submitForm(attr: string) {
    const req = {
      attr_update: attr,
      status: CallStatus.missed,
    }
    this.spinner.show();
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.submit,
        this.spTracker.labels.leaveMsg, '', this.queuePositionId, this.context);
    this.videoCallService.queuePositionUpdate(this.queuePositionId, this.guestToken, req).subscribe(() => {
      this.gtmService.sendGtm(GtmTrackers.optimyLeaveMessageCompleted);
      this.gtmService.sendGtm(GtmTrackers.optimyLeftQueue);
      this.spinner.hide();
      this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo, PluginStates.outboundFirstInitiated]);
      this.activeSection = 3;
      this.setActiveScreen();
    })
  }
  closeLeaveMessage() {
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], {skipLocationChange: true});
  }
}
