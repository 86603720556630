import { Participant } from 'src/app/components/video-call/video-call.types';
import {
  ChatMessage,
  ChatRes,
  ConversationWebHookMessage,
  CustomButton,
  CustomMessage,
  CustomMessageOptions,
  MessageCreateReq,
  MessageUpdateReq,
  TypingInfo
} from '../../../core/constants/chat.modal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { firstValueFrom, interval, Observable, startWith, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { EventTypes, RouteSate } from 'src/app/core/constants/call.modal';
import {
  GtmTrackers,
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels,
  SnowplowTrackerProperties
} from 'src/app/core/constants/trackerLabels';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { SocketService } from 'src/app/core/services/socket/socket.service';
import { ScreenOrientationService } from 'src/app/core/services/utils/screen-orientation.service';
import { VideoCallService } from 'src/app/core/services/video-call/video-call.service';
import { v4 as uuidv4 } from 'uuid';
import { AgentLists, AgentStatus } from '../../../core/constants/agent.modal';
import { CallStatus, TimeExceedAction } from '../../../core/constants/call.modal';
import { PluginStates, ResizeState, RoomInfo, RoomInfoStates } from '../../../core/constants/common.enum';
import { ResizeService } from '../../../core/services/resize/resize.service';
import { SharedService } from '../../../core/services/shared/shared.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { MessageStatus } from '../models/chatModels';
import { RecordingService } from '../../../core/services/recording/recording.service';
import { GtmService } from '../../../core/services/gtm/gtm.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() identity: string;
  @Input() channelId: string;
  @Input() isOutboundCall: boolean;
  @Input() currentTypingUsers: string[] = [];
  @Input() unreadMessageCount: number;
  @Input() isUserInQueue: boolean
  @Output() updateUnreadMsgCount = new EventEmitter<number>();
  @Output() showReplyButton = new EventEmitter();
  @ViewChild('chatDiv') chatDiv: ElementRef;
  @ViewChild('messageField') messageField: ElementRef;

  messageList: ChatMessage[] = [];

  container: HTMLElement;
  guestUserId: string;
  nextPageUrl: string;
  isFirstInitiated: boolean;
  inRoutingState = false;
  maximize = false;
  maximizeLandscapeView = false;
  showToBottomBtn = false;
  lastScrollPosition: number;
  lastConsumedMessageIndex: number;
  customMessageOptions = CustomMessageOptions;
  roomInfo: RoomInfo;
  agentHasJoined: boolean = false;
  textEnabledForRouting: boolean;

  canPublish = true;
  throttleTime = 5000;
  isTyping = false;

  chatForm: UntypedFormGroup = this.formBuilder.group({
    chatMessage: new UntypedFormControl('', [Validators.required]),
  });

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  currentCategory: string;


  destroy$: Subject<boolean> = new Subject<boolean>();
  timerForRetrieveMessage$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private screenOrientationService: ScreenOrientationService,
    private socketService: SocketService,
    private sharedService: SharedService,
    private zone: NgZone,
    private chatService: ChatService,
    private videoCallService: VideoCallService,
    private resizeService: ResizeService,
    private utils: UtilsService,
    private snowplowService: SnowplowService,
    private recordingService: RecordingService,
    private gtmService: GtmService,
  ) {
    this.socketService.messageData
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: (data: ConversationWebHookMessage) => {
            this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
            if (this.channelId === data.ConversationSid) {
              if ((data?.Author !== this.guestUserId && data.EventType === EventTypes.messageAdded)
                  || data.EventType === EventTypes.messageUpdated) {
                if (typeof data.Attributes === 'string') {
                  data.Attributes = JSON.parse(data.Attributes);
                }
                this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
                data.showMessage = this.chatService.showMessage(data?.Attributes, this.guestUserId);
                data.participantName = this.chatService.getParticipantName(data.Author);
                if (data?.Attributes?.custom_message) {
                  data.showCustomOptions = this.chatService.showButtons(data?.Attributes?.custom_message, this.guestUserId);
                }
                const lastIndex = data?.Attributes?.index;
                this.unreadMessageCount = ((lastIndex ?? 0) - this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.unConsumedIndex));
                this.updateMessage(data);
                this.updateMessageIndex();
                this.setScroll();
              }
            }
          }
        });

    this.resizeService.resizeSub.pipe(takeUntil(this.destroy$)).subscribe((resp: ResizeState) => {
      this.maximize = resp.isMaximized;
      const orientation = !window?.matchMedia('(orientation: portrait)')?.matches && this.utils.isOnMobile();
      this.maximizeLandscapeView = this.maximize && orientation;
    });

    this.sharedService.agentJoiningCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe((agentJoiningCall: boolean) => {
        this.agentHasJoined = agentJoiningCall;
      });

    this.sharedService.enteredRoutingState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          if(state) {
            this.inRoutingState = this.utils.checkRoutingEnabledAndRoomStatus();
          }
        });
  }

  ngOnInit(): void {
    this.inRoutingState = this.utils.checkRoutingEnabledAndRoomStatus();
    this.textEnabledForRouting = this.utils.getSessionVal(AgentStatus.agentStatusInfo)?.join_queue_config?.text_during_routing;
    this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
    this.lastConsumedMessageIndex = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.unConsumedIndex);

    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.agentHasJoined = this.roomInfo?.roomStatus === CallStatus.in_progress;

    this.updateCurrentCategoryValue();
    this.sendTyping(false);
    const checkOutboundFirstInitiated = this.utils.getLocalVal(PluginStates.outboundFirstInitiated);
    if (this.isOutboundCall && !checkOutboundFirstInitiated) {
      this.isFirstInitiated = true;
      setTimeout(() => {
        this.isFirstInitiated = false;
        this.startReplyButtonInterval();
      }, 2000);
      this.utils.setLocalVal(null, [PluginStates.outboundFirstInitiated], [true]);
    } else {
      this.isFirstInitiated = false;
      this.showReplyButton.emit(true);
    }
    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateCurrentCategoryValue();
      });
    interval(30000)
        .pipe(takeUntil(this.timerForRetrieveMessage$), startWith(0))
        .subscribe(() => {
          if(this.channelId) {
            this.updateMessageList();
          }
        });
  }

  private updateMessageList(): void {
    this.chatService.getChatMessage(this.channelId).subscribe((res: ChatRes) => {
      let messages = res?.message.map((msg: ChatMessage) => {
        const message = { ...msg };
        message.updatedTime = moment(message.dateCreated).format('LT');
        this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
        message.showMessage = this.chatService.showMessage(message.attributes, this.guestUserId);
        message.participantName = this.chatService.getParticipantName(message.author);
        if (message.attributes?.custom_message) {
          message.showCustomOptions = this.chatService.showButtons(message?.attributes?.custom_message, this.guestUserId);
        }
        return message;
      });
      if(this.isOutboundCall) {
        messages = messages?.filter(res=> res?.author !== 'system');
      }
      this.messageList = messages;
      this.addMessagesNotSent();
    });
  }

  ngAfterViewInit() {
    if (!this.isOutboundCall) {
      const lastChatScrollPosition = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.lastChatScrollPosition);
      setTimeout(() => {
        this.chatDiv?.nativeElement.scrollTo({ top: lastChatScrollPosition, behavior: 'smooth' });
      }, 500)
      this.checkScrollPosition();
      this.updateMessageIndex();
      this.lastScrollPosition = this.chatDiv.nativeElement.scrollTop;
    }
  }

  public sendSnowplowEvent(): void {
    this.updateCurrentCategoryValue();
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click,
      this.spTracker.labels.chatField);
  }
  private updateCurrentCategoryValue(): void {
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    const isUserInQueue = (this.roomInfo?.roomStatus !== CallStatus.in_progress);
    this.currentCategory = this.chatService.updateCurrentCategoryValue(isUserInQueue, this.maximize);
  }

  updateMessageIndex() {
    if (this.chatDiv.nativeElement.scrollHeight <= this.chatDiv.nativeElement.offsetHeight) {
      this.updateUnreadMsgCount.emit(0);
      this.updateMessageIndexInTwilio();
    }
  }

  startReplyButtonInterval() {
    setTimeout(() => {
      this.showReplyButton.emit(true);
    }, 500);
  }

  public autoGrow(element: any): void {
    if ((element.scrollHeight) + 'px' === element.style.height) return;
    if (element.scrollHeight < 50) {
      element.style.height = '40px';
      return;
    }
    if (element.scrollHeight >= 110) {
      element.style.height = '92px';
    } else {
      element.style.height = '5px';
      element.style.height = (element.scrollHeight) + 'px';
    }
  }

  setScroll() {
    setTimeout(() => {
      if (this.chatDiv) {
        this.chatDiv.nativeElement.scrollTo({ top: (this.chatDiv.nativeElement.scrollHeight), behavior: 'smooth' });
      }
    }, 300);
  }

  sendMessageFn() {
    if (this.chatForm?.controls?.chatMessage?.value === '\n') {
      this.chatForm?.reset();
    } else {
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.send,
        this.spTracker.labels.chatField);
      this.sendMessage();
    }
  }


  retryCreateMessage(message: ChatMessage) {
    const index = this.messageList.findIndex((item) => {
      return item.attributes.messageCode === message.attributes.messageCode;
    });
    if (index !== -1) {
      // remove message from the list
      this.messageList.splice(index, 1);
      this.removeMessageFromStorage(message);
      this.sendMessage(message);
    }
  }

  private getIdentityFromParticipantList(): string {
    const participantsList = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.participantList);
    if(participantsList?.length){
      const domain_userid = this.snowplowService.getSnowplowUserId();
      const participant = participantsList?.find((participant: Participant) => {
        return participant.attributes.domain_userid === domain_userid;
      });
      return participant.identity;
    } else {
      return this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
    }
  }

  sendMessage(messageRetry: ChatMessage | undefined = undefined) {
    if (!this.identity) {
      this.identity = this.getIdentityFromParticipantList();
    }
    const req: MessageCreateReq = {
      channelSid: this.channelId,
      message: messageRetry ? messageRetry.body : this.chatForm?.controls?.chatMessage?.value.replace(/^\n+|\n+$/g, '').trim(),
      identity: this.identity,
      attributes: {
        messageCode: messageRetry
          ? messageRetry.attributes.messageCode
          : uuidv4(),
        status: MessageStatus.PENDING
      }
    }
    const currentMessage: ChatMessage = this.getMessageFormatted(req);
    this.messageList.push(currentMessage);
    this.sendTyping(false);
    this.addMessageToStorage(currentMessage);
    this.setScroll();
    this.chatForm?.reset();
    this.chatService.sendChatMessages(req)
      .pipe(
        catchError((err: any, caught: Observable<ChatMessage>) => {
          this.updateMessageStatusToFailed(currentMessage);
          return caught;
        })
      )
      .subscribe((message: ChatMessage) => {
        message.updatedTime = moment(message.dateCreated).format('LT');
        this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
        message.showMessage = this.chatService.showMessage(message.attributes, this.guestUserId);
        if (message?.attributes?.custom_message) {
          message.showCustomOptions =
            this.chatService.showButtons(message.attributes.custom_message, this.guestUserId);
        }
        // this.messageList?.push(res.message);
        message.attributes = JSON.parse(message.attributes as string);
        this.updateMessageListItem(message);
        this.removeMessageFromStorage(message);
        this.setScroll();
        this.updateMessageIndex();
        const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
        if (this.utils.checkRoutingEnabledAndRoomStatus() || (roomInfo?.roomStatus === CallStatus.routing && this.textEnabledForRouting)) {
          this.updateQueueForRouting();
        }
      });
    this.messageField.nativeElement.focus();
  }

  private updateQueueForRouting() {
    this.inRoutingState = false;
    const attr: any = { status: CallStatus.pending};
    this.utils.setLocalVal(
        PluginStates.roomInfo,
        [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
        [CallStatus.pending, RouteSate.waiting]
    );
    const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    const queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
    this.gtmService.sendGtm(GtmTrackers.optimyJoinQueue);
    this.videoCallService.queuePositionUpdate(queuePositionId, roomInfo.guestToken, attr).subscribe(()=>{
      this.chatService.startInterruptMessage$.next(true);
      console.log('guest joined the queue')
    });
  }

  private updateMessageListItem(message: ChatMessage) {
    const index = this.messageList.findIndex((item) => {
      return (
        (!!item.sid && !!message.sid &&
          (item.sid === message.sid)) ||
        (!!item.attributes.messageCode && !!message.attributes.messageCode &&
          (item.attributes.messageCode === message.attributes.messageCode)
        )
      );
    });
    if (index !== -1) {
      this.messageList[index] = message;
    } else {
      this.messageList.push(message);
    }
  }


  private updateMessageStatusToFailed(message: ChatMessage) {
    if (!message.attributes.messageCode) return;
    // somehow the message status was not allowed to be updated,
    // the JSON parse is to recreate the object without the restriction
    const newMessage = JSON.parse(JSON.stringify(message));
    newMessage.attributes.status = MessageStatus.FAILED;
    this.updateMessageListItem(newMessage);
  }


  private getMessageFormatted(req: MessageCreateReq): ChatMessage {
    return {
      conversationSid: req.channelSid,
      body: req.message,
      author: req.identity,
      showMessage: true,
      attributes: req.attributes ? { ...req.attributes } : {},
    };
  }

  updateMessage(msg: ConversationWebHookMessage) {
    const message: ChatMessage = {
      accountSid: msg.AccountSid,
      attributes: msg.Attributes,
      body: msg.Body,
      // conversationSid: msg.conversationSid,
      dateCreated: msg.DateCreated,
      dateUpdated: '',
      author: msg.Author,
      index: Number.parseInt(msg.Index),
      lastUpdatedBy: '',
      media: '',
      // participantSid: msg.participantSid,
      sid: msg.MessageSid,
      url: '',
      updatedTime: moment(msg.DateCreated).format('LT'),
      showMessage: msg.showMessage,
      showCustomOptions: msg.showCustomOptions,
      participantName: msg.participantName
    }
    this.updateMessageListItem(message);
  }

  ngOnDestroy() {
    if (!this.unreadMessageCount) {
      this.updateMessageIndexInTwilio();
    }
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastChatScrollPosition],
      [this.lastScrollPosition]);
    this.destroy$.next(true);
    this.timerForRetrieveMessage$.next(true);
  }

  checkScrollPosition() {
    if (!this.channelId) return;
    if (this.chatDiv.nativeElement.scrollTop === 0 && this.nextPageUrl) {
      const pageToken = this.nextPageUrl?.split('=')[4];
      this.chatService.getChatMessage(this.channelId, pageToken).subscribe((res: any) => {
        if (res.message) {
          const firstMessageId = this.messageList[0]?.sid;
          let messages =  [...res?.message, ...this.messageList];
          if(this.isOutboundCall) {
            messages = messages?.filter(res=> res?.author !== 'system');
          }
          this.messageList = messages;
          this.addMessagesNotSent();
          this.nextPageUrl = res?.nextPageUrl;
          this.chatDiv.nativeElement.scrollTop = this.chatDiv.nativeElement.querySelector(`#${firstMessageId}`)?.getBoundingClientRect()?.bottom;
        }
      });
    }
    if (this.chatDiv.nativeElement.scrollHeight > this.chatDiv.nativeElement.offsetHeight) {
      this.showToBottomBtn = (this.chatDiv.nativeElement.scrollTop + this.chatDiv.nativeElement.offsetHeight) < (this.chatDiv.nativeElement.scrollHeight - 5);
      if (!this.showToBottomBtn) {
        this.unreadMessageCount = 0;
        this.updateMessageIndexInTwilio();
        this.updateUnreadMsgCount.emit(0);
      }
    }
    this.lastScrollPosition = this.chatDiv.nativeElement.scrollTop;
  }


  sendTyping(isTyping: boolean) {
    const req: TypingInfo = {
      isTyping,
      channelId: this.channelId,
      identity: this.guestUserId
    }
    this.isTyping = isTyping;
    if (this.isTyping) {
      if (this.canPublish) {
        this.chatService.sendTypingStatus(req).subscribe(() => { });
        this.canPublish = false;
        setTimeout(() => {
          this.canPublish = true;
        }, this.throttleTime);
        setTimeout(() => {
          if (this.isTyping) {
            this.sendTyping(false);
          }
        }, this.throttleTime * 2);
      }
    } else {
      this.canPublish = true;
      this.chatService.sendTypingStatus(req).subscribe(() => { });
    }
  }

  updateConsumedIndex() {
    this.updateMessageIndexInTwilio();
    this.setScroll();
    this.unreadMessageCount = 0;
    this.updateUnreadMsgCount.emit(0);
  }

  updateMessageIndexInTwilio() {
    let attributes = this.messageList[this.messageList.length - 1]?.attributes;
    if (typeof attributes === 'string') {
      attributes = JSON.parse(attributes);
    }
    const lastIndex = attributes?.index;
    this.lastConsumedMessageIndex = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.unConsumedIndex);
    if (this.messageList?.length && this.lastConsumedMessageIndex !== (lastIndex)) {
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.unConsumedIndex], [lastIndex]);
      this.chatService.updateLastConsumedMessageIndex(this.guestUserId, this.channelId, (lastIndex ?? 0))
        .subscribe(() => { });
    }
  }

  public disableButton(message: CustomMessage): boolean {
    return (message.event_key?.includes('interrupt') && this.agentHasJoined);
  }

  executeBtnAction(message: ChatMessage, response: CustomButton, event?: Event) {
    if(response?.url){
      window.open(response.url, '_blank');
      this.closePlugin();
      return;
    }
    event?.stopPropagation();
    event?.preventDefault();
    if (!message?.attributes?.custom_message?.response_value) {
      if (!response.action || response.action === TimeExceedAction.skip) {
        if (message.attributes.custom_message) {
          message.attributes.custom_message.response_value = response.text;
          message.attributes.custom_message.response_from = this.identity;
          const req: MessageUpdateReq = {
            channelSid: this.channelId,
            identity: this.identity ? this.identity : this.getIdentityFromParticipantList(),
            chatServiceSid: message?.sid || '',
            message: message.body,
            attributes: message.attributes
          }
          this.chatService.updateChatMessage(req).subscribe(() => { });
        }
      }
      const isRouting = this.utils.checkRoutingEnabledAndRoomStatus();
      if (isRouting && response?.queue_id) {
        const nextQuestionDetails = this.checkQueueIdStatus(response);
        if (nextQuestionDetails) {
          response.nextQuestionDetails = nextQuestionDetails;
        }
      }
      if (response.text) {
        this.updateQueueWithAnswer(message, response, isRouting);
      }
      this.chatService.executeButtonAction$.next(response);
      this.sendSnowplowEventForAction(message, response);
      if (response.text === 'Allow') {
        document.getElementById('op-coBrowse-ok')?.click();
      } else if (response.text.toLowerCase() === 'null' || response.text.toLowerCase() === 'decline') {
        document.getElementById('op-coBrowse-cancel')?.click();
      }
    }
  }

  closePlugin(){
    this.sharedService.closeCall();
    this.videoCallService.inCall$.next({ inCall: false });
    this.recordingService.stopRecordInteractionAction$.next(true);
  }


  private isActionSelectedAndNotSkip(responseSelected: CustomButton): boolean {
    return !!responseSelected?.action &&
      responseSelected?.action !== TimeExceedAction.skip;
  }

  private sendSnowplowEventForAction(message: ChatMessage, response: CustomButton): void {
    if (this.isActionSelectedAndNotSkip(response)) {
      this.updateCurrentCategoryValue();
      this.snowplowService.trackStructEvent(this.currentCategory, response.text, '',
        message.attributes?.custom_message?.event_key);
    }
  }


  private async updateQueueWithAnswer(message: ChatMessage, response: CustomButton, isRouting?: boolean): Promise<void> {
      const attrUpdate: any = {
        attr_update: `{"custom_fields.${message.attributes?.custom_message?.event_key}" : "${response.text}"}`
      }
      if (response.queue_id) {
        attrUpdate.queue_id = response.queue_id
        this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.queueId], [response.queue_id]);
      }

      const currentStatus = this.utils.getLocalVal(
          PluginStates.roomInfo,
          RoomInfoStates.roomStatus
      );

      if (isRouting && response?.queue_id && response?.nextQuestionDetails?.success && currentStatus === CallStatus?.routing) {
        attrUpdate.status = CallStatus.pending;
        this.utils.setLocalVal(
            PluginStates.roomInfo,
            [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
            [CallStatus.pending, RouteSate.waiting]
        );
        this.inRoutingState = this.utils.checkRoutingEnabledAndRoomStatus();
      }

      if(this.textEnabledForRouting && currentStatus === CallStatus.routing){
        attrUpdate.status = CallStatus.pending;
        this.utils.setLocalVal(
            PluginStates.roomInfo,
            [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
            [CallStatus.pending, RouteSate.waiting]
        );
      }

      const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
      const queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
      this.gtmService.sendGtm(GtmTrackers.optimyJoinQueue);
      await firstValueFrom(this.videoCallService.queuePositionUpdate(queuePositionId, roomInfo.guestToken, attrUpdate));
  }

  checkQueueIdStatus(response: CustomButton) {
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    if (this.findQueueIdIsIncludeOrNot(agentDetails?.agents, response?.queue_id)) {
      return { success: true, nextQuestionIndex: response?.skip_to_question };
    } else {
      return { success: false, nextQuestionIndex: response?.skip_to_question_fail };
    }
  }

  findQueueIdIsIncludeOrNot(agents: AgentLists[], currentQueueId: any) {
    const queueId = agents?.find((agent) => agent?.queue_id?.includes(currentQueueId));
    return !!queueId;
  }

  private addMessagesNotSent() {
    this.messageList.forEach((msg: ChatMessage) => {
      this.removeMessageFromStorage(msg);
    });
    this.messageList.push(...this.getMessagesNotSent());
  }

  private getMessagesNotSent(): ChatMessage[] {
    const messages = this.getMessagesNotSentFromStorage();
    const messagesNotSentToTwilio: ChatMessage[] = [];
    if (messages) {
      Object.keys(messages).forEach((key: string) => {
        if (messages[key].conversationSid === this.channelId) {
          (messages[key] as ChatMessage).attributes.status = MessageStatus.FAILED;
          messagesNotSentToTwilio.push(messages[key]);
        } else {
          this.removeMessageFromStorage(messages[key]);
        }
      });
    }
    return messagesNotSentToTwilio;
  }

  private getMessagesNotSentFromStorage(): { [key in string]: ChatMessage } {
    const roomInfo: RoomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    return roomInfo?.sentMessages || {};
  }

  private removeMessageFromStorage(message: ChatMessage): void {
    const messages = this.getMessagesNotSentFromStorage();
    if (Object.keys(messages).length === 0) return;
    if (message.attributes.messageCode && messages[message.attributes.messageCode]) {
      delete messages[message.attributes.messageCode];
      this.utils.setLocalVal(PluginStates.roomInfo, ['sentMessages'], [messages]);
    }
  }

  private addMessageToStorage(message: ChatMessage) {
    const sentMessages = this.getMessagesNotSentFromStorage();
    if (!sentMessages) return;
    if (message.attributes.messageCode) {
      sentMessages[message.attributes.messageCode] = message;
      this.utils.setLocalVal(PluginStates.roomInfo, ['sentMessages'], [sentMessages]);
    }
  }

  showAuthorName(message: ChatMessage, i: number): boolean {
    return this.identity !== message?.author && (i === 0 || message?.author !== this.messageList[i - 1]?.author) &&
      message?.attributes?.custom_message?.type !== this.customMessageOptions.cobrowse_permission;
  }

  getClass(response: string, btnType: string): string {
    return response ? 'op-btn-flat' : `op-btn-${btnType}`;
  }

  showActionButton(message: ChatMessage, btn: CustomButton): boolean {
    if (btn.action === TimeExceedAction.booking) {
      const pluginConfigs = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
      return !pluginConfigs?.booking_config?.defaults.disabled && (!message?.attributes?.custom_message?.response_value ||
        message?.attributes?.custom_message?.response_value === btn.text);
    } else if (btn.action === TimeExceedAction.leaveMessage) {
      const pluginConfigs = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
      return !pluginConfigs?.booking_config?.defaults.leave_message_disabled;
    } else {
      return !message?.attributes?.custom_message?.response_value ||
        message?.attributes?.custom_message?.response_value === btn.text
    }
  }

  @HostListener('window:beforeunload')
  pageRefreshed() {
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastChatScrollPosition],
      [this.lastScrollPosition]);
    const message = this.messageList.find((msg: any) => {
      return !msg.attributes?.custom_message?.response_value
        && msg?.attributes?.custom_message?.type === this.customMessageOptions.cobrowse_permission;
    })
    if (message) {
      this.executeBtnAction(message, { text: 'null' });
      if ((<any>window)?.CobrowseIO?.currentSession && ((<any>window)?.CobrowseIO?.currentSession.remoteControl() === 'requested')) {
        (<any>window).CobrowseIO.currentSession.end();
      }
    }

  }
}
